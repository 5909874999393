import React from 'react';

import { Line, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { IBaseElement, ISize } from '@amaui/ui-react/types';

import { Meta } from '.';

const useStyles = style(theme => ({
  root: {

  },

  wrapper: {
    height: 0,
    overflow: 'hidden auto',
    padding: '24px 16px 140px'
  },

  header: {
    flex: '0 0 auto',
    maxWidth: 814
  },

  main: {
    boxShadow: theme.shadows.values.default[1],
    borderRadius: 8,
    maxWidth: 814,
    background: theme.palette.background.default.primary,
    padding: '40px 24px 40px'
  },

  footer: {
    padding: '8px 0',
    overflow: 'auto hidden'
  },

  footerPart: {
    flex: '0 0 auto'
  }
}), { name: 'amaui-app-Paper' });

export interface IPaper extends IBaseElement {
  name?: string;

  app?: string;

  meta?: boolean;

  update?: boolean;

  startFooter?: any;

  endFooter?: any;

  midFooter?: any;

  width?: number;

  showName?: boolean;

  size?: ISize;
}

const Element: React.FC<IPaper> = React.forwardRef((props, ref) => {
  const {
    name,

    app = 'amaui',

    meta = true,

    size = 'regular',

    startFooter,

    midFooter,

    endFooter,

    width,

    showName = false,

    className,

    style,

    children,

    ...other
  } = props;

  const { classes } = useStyles();

  const withHeader = name && showName;

  const withFooter = startFooter || midFooter || endFooter;

  const footerPartProps: any = {
    gap: 1,
    direction: 'row',
    align: 'center',
    className: classes.footerPart
  };

  return <>
    {meta && (
      <Meta
        app={app}

        name={name}
      />
    )}

    <Line
      ref={ref}

      gap={1.5}

      direction='column'

      justify='unset'

      align='center'

      flex

      fullWidth

      className={classes.wrapper}
    >
      {withHeader && (
        <Line
          gap={1}

          direction='row'

          justify='space-between'

          align='center'

          fullWidth

          className={classes.header}
        >
          {name && showName && (
            <Type
              version={size === 'large' ? 'd2' : size === 'regular' ? 'd3' : 'h1'}

              align='start'
            >
              {name}
            </Type>
          )}
        </Line>
      )}

      <Line
        gap={7}

        direction='column'

        justify='unset'

        align='unset'

        flex

        fullWidth

        className={classNames([
          className,
          classes.main
        ])}

        {...other}

        style={{
          maxWidth: width,

          ...style
        }}
      >
        <Line
          gap={0}

          direction='column'

          justify='unset'

          align='unset'

          flex

          fullWidth
        >
          {children}
        </Line>

        {withFooter && (
          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classes.footer}
          >
            <Line
              {...footerPartProps}
            >
              {startFooter}
            </Line>

            <Line
              {...footerPartProps}
            >
              {midFooter}
            </Line>

            <Line
              {...footerPartProps}
            >
              {endFooter}
            </Line>
          </Line>
        )}
      </Line>
    </Line>
  </>;
});

export default Element;
