import React from 'react';

import { Line, Tab, Tabs, Type } from '@amaui/ui-react';
import { classNames } from '@amaui/style-react';

import IconMaterialGroupsW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialGroupsW100';
import IconMaterialPersonFilledW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialPersonFilledW100';

import { FormUserAdd, FormUserGroup, FormUserUpdate, Items, NoResults, Page } from 'ui';
import { OrganizationService, UserGroupService } from 'services';
import ItemEmployee from './ItemUser';
import ItemEmployeeGroup from './ItemUserGroup';

const Element = React.forwardRef(() => {
  const [tab, setTab] = React.useState('Employees');

  const onChangeTabs = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const employees = React.useMemo(() => {
    const search: any = [
      {
        name: 'Name',
        type: 'text',
        property: 'text'
      },
      {
        name: 'Added at',
        type: 'date',
        property: 'added_at'
      }
    ];

    const queryObjectsName = 'queryUsers';

    const selectObjectsName = 'selectedUsers';

    return (
      <Page
        name='Employees'

        singular='employee'

        plural='employees'

        service={OrganizationService}

        queryObjectsName={queryObjectsName}

        selectObjectsName={selectObjectsName}

        getMethodName='getUser'

        search={search}

        FormAdd={FormUserAdd}

        FormUpdate={FormUserUpdate}

        className={classNames([
          'amaui-Employees'
        ])}
      >
        {({ response }) => {
          if (!response?.length) return <NoResults />;

          return (
            <Items
              service={OrganizationService}

              queryObjectsName={queryObjectsName}

              selectObjectsName={selectObjectsName}
            >
              {response?.map((item: any, index: number) => (
                <ItemEmployee
                  key={index}

                  object={item}
                />
              ))}
            </Items>
          );
        }}
      </Page>
    );
  }, []);

  const employeeGroups = React.useMemo(() => {
    const search: any = [
      {
        name: 'Name',
        type: 'text',
        property: 'text'
      },
      {
        name: 'Added at',
        type: 'date',
        property: 'added_at'
      }
    ];

    return (
      <Page
        name='Employee groups'

        singular='employee group'

        plural='employee groups'

        service={UserGroupService}

        search={search}

        FormAdd={FormUserGroup}

        FormUpdate={FormUserGroup}

        secondary

        addProps={{
          to: ''
        }}

        updateProps={{
          to: ''
        }}

        className={classNames([
          'amaui-EmployeeGroups'
        ])}
      >
        {({ response }) => {
          if (!response?.length) return <NoResults />;

          return (
            <Items
              service={UserGroupService}
            >
              {response?.map((item: any, index: number) => (
                <ItemEmployeeGroup
                  key={index}

                  object={item}
                />
              ))}
            </Items>
          );
        }}
      </Page>
    );
  }, []);

  const tabs = React.useMemo(() => {
    return [
      { name: 'Employees', Icon: IconMaterialPersonFilledW100Rounded },
      { name: 'Employee groups', Icon: IconMaterialGroupsW100Rounded }
    ];
  }, []);

  const iconProps = {
    size: 'large'
  };

  return (
    <Line
      flex

      fullWidth
    >
      <Tabs
        value={tab}

        onChange={onChangeTabs}

        justify='center'

        size='small'

        noDivider
      >
        {tabs?.map((item: any) => (
          <Tab
            key={item.name}

            value={item.name}

            icon={item.Icon ? <item.Icon {...iconProps} /> : null}

            style={{
              maxWidth: 'unset'
            }}
          >
            <Type
              version='t2'

              whiteSpace='nowrap'
            >
              {item.name}
            </Type>
          </Tab>
        ))}
      </Tabs>

      <Line
        gap={0}

        flex

        fullWidth
      >
        {tab === 'Employees' && employees}

        {tab === 'Employee groups' && employeeGroups}
      </Line>
    </Line>
  );
});

export default Element;
