import React from 'react';
import { useNavigate } from 'react-router-dom';

import { hash } from '@amaui/utils';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { IconButton, Interaction, Line, List, ListItem, Menu, Tooltip, Type } from '@amaui/ui-react';

import IconMaterialOpenInNewRounded from '@amaui/icons-material-rounded-react/IconMaterialOpenInNewW100';
import IconMaterialAppsRounded from '@amaui/icons-material-rounded-react/IconMaterialAppsW100';

import { ReactComponent as LogoWebsite } from 'assets/svg/logos/logo-website.svg';
import { ReactComponent as LogoTask } from 'assets/svg/logos/logo-task.svg';
import { ReactComponent as LogoNote } from 'assets/svg/logos/logo-note.svg';
import { ReactComponent as LogoUrlShortener } from 'assets/svg/logos/logo-urlShortener.svg';
import { ReactComponent as LogoChat } from 'assets/svg/logos/logo-chat.svg';
import { ReactComponent as LogoContact } from 'assets/svg/logos/logo-contact.svg';
import { ReactComponent as LogoLocation } from 'assets/svg/logos/logo-location.svg';
import { ReactComponent as LogoPersonalTrainer } from 'assets/svg/logos/logo-personal-trainer.svg';

import { Divider, useSubscription } from 'ui';
import { AuthService, StorageService } from 'services';
import { ISignedIn, getAppRootURL } from 'other';
import config from 'config';

const useStyle = style(theme => ({
  root: {

  },

  menu: {
    padding: '0 0 12px',
    overflow: 'hidden',

    '&.amaui-Line-root': {
      width: '100vw',
      maxWidth: 314,
      borderRadius: theme.methods.shape.radius.value(1),
      boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',
      background: theme.palette.light ? theme.palette.color.neutral[100] : theme.palette.color.neutral[5]
    },

    '& .amaui-ListItem-wrapper': {
      background: 'none'
    }
  },

  header: {
    padding: '24px 20px 12px',
    cursor: 'default',
    userSelect: 'none'
  },

  mainApps: {
    padding: 16
  },

  mainLogo: {
    height: 44,
    color: theme.palette.text.default.primary
  },

  items: {
    padding: '8px 16px 8px'
  },

  item: {
    position: 'relative',
    width: 94,
    padding: 8,
    borderRadius: 8,
    cursor: 'pointer',
    transition: theme.methods.transitions.make('transform'),
    userSelect: 'none',

    '& svg': {
      width: 41
    },

    '& .amaui-Type-root': {
      width: '100%',
      whiteSpace: 'normal',
      wordBreak: 'break-word'
    },

    '&:active': {
      transform: 'scale(0.94)'
    }
  }
}), { name: 'amaui-app-AppsMenu' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();

  const navigate = useNavigate();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [open, setOpen] = React.useState(false);

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const to = React.useCallback((value: string) => {
    navigate(value);

    onClose();
  }, []);

  const is = signedIn?.user?.is;

  const apps = React.useMemo(() => {
    return [
      ...(is?.user ? [{
        name: 'Website',
        logo: LogoWebsite,
        to: getAppRootURL('app_website', '/websites', signedIn),
        props: {
          className: 'amaui-logo-website'
        }
      }] : []),

      ...(is?.user ? [{
        name: 'Chat',
        logo: LogoChat,
        to: getAppRootURL('app_chat', '/chats', signedIn),
        props: {
          className: 'amaui-logo-chat'
        }
      }] : []),

      ...(is?.user ? [{
        name: 'URL Shortener',
        logo: LogoUrlShortener,
        to: getAppRootURL('app_urlShortener', '/url-shorteners', signedIn),
        props: {
          className: 'amaui-logo-url-shortener'
        }
      }] : []),

      ...(is?.user ? [{
        name: 'Notes',
        logo: LogoNote,
        to: getAppRootURL('app_note', '/notes', signedIn),
        props: {
          className: 'amaui-logo-note'
        }
      }] : []),

      ...(is?.user ? [{
        name: 'Tasks',
        logo: LogoTask,
        to: getAppRootURL('app_task', '/tasks', signedIn),
        props: {
          className: 'amaui-logo-task'
        }
      }] : []),

      ...(is?.user ? [{
        name: 'Location',
        logo: LogoLocation,
        to: getAppRootURL('app_location', '/locations', signedIn),
        props: {
          className: 'amaui-logo-location'
        }
      }] : []),

      ...(is?.user ? [{
        name: 'Contact',
        logo: LogoContact,
        to: getAppRootURL('app_contact', '/contacts', signedIn),
        props: {
          className: 'amaui-logo-contact'
        }
      }] : [])
    ];
  }, [hash(is)]);

  const onOpenLink = React.useCallback((url: string) => {
    window.open(url);
  }, []);

  const mainApps = React.useMemo(() => {
    return [
      {
        name: 'Personal Trainer',
        logo: LogoPersonalTrainer,
        to: `${config.value.apps.personal_trainer.url}?amaui_token=${StorageService.get('token')}`,
        props: {
          className: 'amaui-logo-personal-trainer'
        }
      }
    ];
  }, []);

  const menu = (
    <Line
      gap={0}

      className={classes.menu}

      fullWidth
    >
      <Line
        className={classes.header}

        fullWidth
      >
        <Type
          version='t2'
        >
          Apps
        </Type>
      </Line>

      <List
        gap={1.75}

        fullWidth

        paddingVertical='none'

        noBackground
      >
        {mainApps.map((item, index) => (
          <ListItem
            key={index}

            onClick={() => onOpenLink(item.to)}

            primary={(
              <Line
                gap={1}

                direction='row'

                align='center'

                style={{
                  color: theme.palette.text.default.primary
                }}
              >
                <item.logo
                  className={classes.mainLogo}
                />

                <IconMaterialOpenInNewRounded
                  color='inherit'

                  size='small'
                />
              </Line>
            )}

            size='small'

            button

            noBackground

            {...item.props}
          />
        ))}
      </List>

      <Divider
        size='small'
      />

      <Line
        gap={0}

        direction='row'

        justify='flex-start'

        wrap='wrap'

        fullWidth

        className={classes.items}
      >
        {apps?.map((item: any) => (
          <Line
            key={item.name}

            gap={1}

            align='center'

            onClick={() => to(item.to)}

            {...item.props}

            className={classNames([
              item.props?.className,
              classes.item
            ])}
          >
            <item.logo />

            <Type
              version='b2'

              align='center'

              color='default'
            >
              {item.name}
            </Type>

            <Interaction />
          </Line>
        ))}
      </Line>
    </Line>
  );

  return (
    <Menu
      open={open}

      onOpen={onOpen}

      onClose={onClose}

      name={menu}

      position='bottom'

      alignment='center'

      AppendProps={{
        offset: [8, 8],
        switch: false
      }}

      {...other}
    >
      <span>
        <Tooltip
          name='Apps'
        >
          <IconButton
            selected={open}
          >
            <IconMaterialAppsRounded />
          </IconButton>
        </Tooltip>
      </span>
    </Menu>
  );
});

export default Element;
