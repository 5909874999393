import React from 'react';

import { is } from '@amaui/utils';

import { Button, useLibrary } from 'ui';
import { mediaToValue } from 'other';

const SelectMedia = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    onChange,

    search = {
      mime: ['image']
    },

    multiple,

    className,

    children,

    ...other
  } = props;

  const media = useLibrary();

  const selected = (is('array', value) ? value : [value]).filter(Boolean);

  const onOpenMedia = React.useCallback(() => {
    media.open({
      search,

      multiple,

      selected,

      onConfirm: (valueNew: any) => {
        const response = is('array', valueNew) ? valueNew.map((item: any) => mediaToValue(item)) : mediaToValue(valueNew);

        onChange(is('array', response) && !multiple ? response[0] : response);
      }
    });
  }, [value, media, search, selected, multiple, onChange]);

  return (
    <Button
      version='outlined'

      color='inherit'

      size='regular'

      onClick={onOpenMedia}

      {...other}
    >
      {children || 'Select'}
    </Button>
  );
});

export default SelectMedia;
