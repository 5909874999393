import React from 'react';

import { copy, getID } from '@amaui/utils';
import { capitalize, classNames, useAmauiTheme } from '@amaui/style-react';
import { Checkbox, FormRow, IconButton, Label, Line, List, ListItem, Menu, MenuItem, Surface, Tooltip, Type, useForm } from '@amaui/ui-react';

import IconMaterialAddRounded from '@amaui/icons-material-rounded-react/IconMaterialAddW100';
import IconMaterialRemoveRounded from '@amaui/icons-material-rounded-react/IconMaterialRemoveW100';
import IconMaterialAlarmRounded from '@amaui/icons-material-rounded-react/IconMaterialAlarmW100';
import IconMaterialCloseRounded from '@amaui/icons-material-rounded-react/IconMaterialCloseW100';

import { Button, Form, NumericTextField, Select } from 'ui';

const Reminders = (props: any) => {
  const {
    value,

    onChange,

    minorMenuOpen,

    onMinorMenuOpen,

    onMinorMenuClose,

    units = [],

    classes
  } = props;

  const theme = useAmauiTheme();

  const [menuReminder, setMenuReminder] = React.useState<any>('default');
  const [openReminder, setOpenReminder] = React.useState<any>();

  const formReminderCustom = useForm({
    values: {
      'reminder': {
        name: 'Reminder',
        is: 'object'
      }
    }
  });

  const refs = {
    root: React.useRef<any>(),
    openReminder: React.useRef(openReminder),
    value: React.useRef(value),
    onChange: React.useRef(onChange),
    formReminderCustom: React.useRef(formReminderCustom)
  };

  refs.value.current = value;

  refs.onChange.current = onChange;

  refs.openReminder.current = openReminder;

  refs.formReminderCustom.current = formReminderCustom;

  const reset = React.useCallback(() => {
    setMenuReminder('default');

    refs.formReminderCustom.current.clear();
  }, []);

  React.useEffect(() => {
    // reset 
    if (minorMenuOpen !== 'reminders') reset();
  }, [minorMenuOpen]);

  const reminderDefaultItems = React.useCallback((index: number) => {
    return [
      ['reminders', true, `${index}.active`],
      ['reminders', 'prior', `${index}.version`],
      ['reminders', 'minute', `${index}.unit`],
      ['reminders', 0, `${index}.value`],
      ['reminders', false, `${index}.repeat`]
    ];
  }, []);

  const reminderCustomSelected = React.useCallback((reminder: any) => {
    return reminder?.active && (
      (
        reminder?.repeat ||
        !(
          (reminder?.active && reminder?.value === 0) ||
          (reminder?.active && reminder?.unit === 'minute' && reminder?.value === 10) ||
          (reminder?.active && reminder?.unit === 'minute' && reminder?.value === 30) ||
          (reminder?.active && reminder?.unit === 'hour' && reminder?.value === 1) ||
          (reminder?.active && reminder?.unit === 'day' && reminder?.value === 1)
        )
      )
    );
  }, [value]);

  const onMenuReminderCustomOkay = React.useCallback((index: number) => {
    onChange([
      ['reminders', true, `${index}.active`],
      ['reminders', 'prior', `${index}.version`],
      ['reminders', refs.formReminderCustom.current.value?.reminder?.unit || 'minute', `${index}.unit`],
      ['reminders', refs.formReminderCustom.current.value?.reminder?.value || 10, `${index}.value`],
      ['reminders', !!refs.formReminderCustom.current.value?.reminder?.repeat, `${index}.repeat`]
    ]);

    setMenuReminder('default');

    setOpenReminder(null);
  }, [onMinorMenuClose, onChange]);

  const onMenuReminderCustomClose = React.useCallback(() => {
    setMenuReminder('default');
  }, [onMinorMenuClose, onChange]);

  const onOpenReminder = React.useCallback((reminder: any) => {
    setOpenReminder((previous: any) => {
      const open = previous?.id === reminder.id ? null : reminder;

      setMenuReminder('default');

      if (open) {
        refs.formReminderCustom.current.onChange('reminder', copy(reminder));
      }

      return open;
    });
  }, []);

  const onOpenCustomReminder = React.useCallback((reminder: any) => {
    refs.formReminderCustom.current.onChange('reminder', copy(reminder));

    setMenuReminder('custom');
  }, []);

  const reminderDefaults = React.useCallback((index: number, reminder: any) => {
    return [
      {
        name: 'On time',
        selected: reminder?.active && reminder?.value === 0,
        onClick: () => {
          onChange([
            ...reminderDefaultItems(index),

            ['reminders', 'minute', `${index}.unit`],
            ['reminders', 0, '0.value']
          ]);

          setOpenReminder(null);
        }
      },
      {
        name: '10 minutes prior',
        selected: reminder?.active && reminder?.unit === 'minute' && reminder?.value === 10,
        onClick: () => {
          onChange([
            ...reminderDefaultItems(index),

            ['reminders', 'minute', `${index}.unit`],
            ['reminders', 10, `${index}.value`]
          ]);

          setOpenReminder(null);
        }
      },
      {
        name: '30 minutes prior',
        selected: reminder?.active && reminder?.unit === 'minute' && reminder?.value === 30,
        onClick: () => {
          onChange([
            ...reminderDefaultItems(index),

            ['reminders', 'minute', `${index}.unit`],
            ['reminders', 30, `${index}.value`]
          ]);

          setOpenReminder(null);
        }
      },
      {
        name: '1 hour prior',
        selected: reminder?.active && reminder?.unit === 'hour' && reminder?.value === 1,
        onClick: () => {
          onChange([
            ...reminderDefaultItems(index),

            ['reminders', 'hour', `${index}.unit`],
            ['reminders', 1, `${index}.value`]
          ]);

          setOpenReminder(null);
        }
      },
      {
        name: '1 day prior',
        selected: reminder?.active && reminder?.unit === 'day' && reminder?.value === 1,
        onClick: () => {
          onChange([
            ...reminderDefaultItems(index),

            ['reminders', 'day', `${index}.unit`],
            ['reminders', 1, `${index}.value`]
          ]);

          setOpenReminder(null);
        }
      },
      {
        name: 'Custom',
        selected: reminderCustomSelected(reminder),
        noMenuCloseOnClick: true,
        onClick: () => onOpenCustomReminder(reminder)
      }
    ].map((item: any, index: number) => (
      <MenuItem
        key={index}

        primary={(
          <Type
            version='b3'
          >
            {item?.name}
          </Type>
        )}

        onClick={item.onClick}

        selected={item.selected}

        size='small'

        button

        menuCloseOnClick={!item.noMenuCloseOnClick}
      />
    ));
  }, []);

  const selectProps: any = {
    IconButtonProps: {
      size: 'small'
    },
    IconProps: {
      size: 'regular'
    },
    fullWidth: true
  };

  const menusReminder: any = (methodItem: any) => {
    const reminder_ = value?.reminders?.find((item: any) => item.id === openReminder?.id);
    const index = value?.reminders?.findIndex((item: any) => item.id === openReminder?.id);

    return {
      default: <>
        <List
          color='themed'

          size='small'

          paddingVertical='none'

          fullWidth
        >
          {reminderDefaults(index, reminder_).map((item: any, index: number) => (
            React.cloneElement(item, methodItem(item, index))
          ))}
        </List>
      </>,

      custom: <>
        <Surface
          color='themed'

          className={classNames([
            classes.menuMore,
            'amaui-reminder'
          ])}

          style={{
            padding: '8px 0'
          }}
        >
          <Line
            gap={1}

            fullWidth
          >
            <FormRow
              MainProps={{
                gap: 1
              }}
            >
              <NumericTextField
                name='Value'

                valueDefault={refs.formReminderCustom.current.values.reminder.value?.value || 10}

                onChange={(valueNew: number) => refs.formReminderCustom.current.onChange('reminder', valueNew, 'value')}

                min={1}

                max={1e3}

                IconButtonProps={{
                  size: 'small'
                }}

                IconProps={{
                  size: 'regular'
                }}

                fullWidth
              />

              <Select
                name='Unit'

                value={refs.formReminderCustom.current.values.reminder.value?.unit || 'minute'}

                onChange={(valueNew: string) => refs.formReminderCustom.current.onChange('reminder', valueNew, 'unit')}

                {...selectProps}
              >
                {['minute', 'hour', 'day', 'month', ...units].map((item: any, indexItem: number) => (
                  <ListItem
                    key={indexItem}

                    primary={(
                      <Type
                        version='b3'
                      >
                        {capitalize(item)}
                      </Type>
                    )}

                    value={item}

                    size='small'

                    button
                  />
                ))}
              </Select>

              <Label
                checked={!!refs.formReminderCustom.current.values.reminder.value?.repeat}

                onChange={(valueNew: any) => refs.formReminderCustom.current.onChange('reminder', valueNew, 'repeat')}

                color='default'

                size='small'
              >
                <Checkbox />

                Repeat
              </Label>
            </FormRow>

            <Line
              direction='row'

              align='center'

              justify='space-between'

              fullWidth
            >
              <Button
                version='text'

                size='small'

                onClick={onMenuReminderCustomClose}
              >
                Close
              </Button>

              <Button
                version='text'

                size='small'

                onClick={() => onMenuReminderCustomOkay(index)}
              >
                Ok
              </Button>
            </Line>
          </Line>
        </Surface>
      </>
    };
  };

  const onClearReminder = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();

    onChange('reminders', []);
  }, [onChange]);

  const onAddReminder = React.useCallback(() => {
    const reminders = value?.reminders || [];

    const reminder = {
      id: getID()
    };

    reminders.push(reminder);

    setOpenReminder(reminder);

    onChange('reminders', reminders);
  }, [value, onChange]);

  const onRemoveReminder = React.useCallback((index: number) => {
    const reminders = value?.reminders || [];

    reminders.splice(index, 1);

    onChange('reminders', reminders);
  }, [value, onChange]);

  const getReminderName = React.useCallback((reminder: any) => {
    if (reminder?.active) {
      if (!!reminder?.value) {
        return `${reminder.value} ${reminder.unit}${reminder.value > 1 ? 's' : ''} prior`;
      }
      else return `On time`;
    }

    return 'Reminder';
  }, []);

  let reminderName = 'Reminder';

  if (value?.reminders?.length) reminderName = `${value.reminders.length} reminders`;

  const iconProps: any = {
    size: 'regular'
  };

  return (
    <Menu
      open={minorMenuOpen === 'reminders'}

      onOpen={() => onMinorMenuOpen('reminders')}

      onClose={onMinorMenuClose}

      name={(methodItem: any) => (
        <Surface
          elevation={theme.palette.light ? 2 : 0}

          color='themed'

          Component={Line}

          gap={1}

          align='center'

          fullWidth

          className={classNames([
            classes.menuMore,
            'amaui-reminder'
          ])}
        >
          <FormRow
            name={(
              <Type
                version='l2'
              >
                {value?.reminders?.length ? `${value?.reminders?.length} reminders` : 'Reminders'}
              </Type>
            )}

            end={(
              <Tooltip
                name='Add reminder'
              >
                <IconButton
                  onClick={onAddReminder}

                  size='small'

                  disabled={value?.reminders?.length >= 3}
                >
                  <IconMaterialAddRounded
                    {...iconProps}
                  />
                </IconButton>
              </Tooltip>
            )}

            gap={0.5}

            fullWidth
          >
            <Line
              gap={0}

              fullWidth
            >
              {value?.reminders?.map((reminder: any, index: number) => (
                <Form
                  key={reminder.id}

                  name={(
                    <Type
                      version='b2'
                    >
                      {getReminderName(reminder)}
                    </Type>
                  )}

                  end={(
                    <Tooltip
                      name='Remove reminder'
                    >
                      <IconButton
                        onClick={() => onRemoveReminder(index)}
                      >
                        <IconMaterialRemoveRounded
                          {...iconProps}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  AccordionProps={{
                    open: openReminder?.id === reminder.id,

                    onChange: () => onOpenReminder(reminder),

                    IconButtonProps: {
                      size: 'small'
                    }
                  }}

                  AccordionMainProps={{
                    style: {
                      margin: 0
                    }
                  }}

                  wrapper

                  accordion
                >
                  {menusReminder(methodItem)[menuReminder]}
                </Form>
              ))}
            </Line>
          </FormRow>
        </Surface>
      )}

      className={classNames([
        classes.minorMenu,
        'amaui-reminder'
      ])}

      style={{
        width: refs.root.current?.clientWidth
      }}
    >
      <ListItem
        ref={refs.root}

        start={(
          <IconMaterialAlarmRounded
            {...iconProps}
          />
        )}

        primary={(
          <Type
            version={!value?.reminders?.some((item: any) => !!item.active) ? 'b2' : 'l2'}

            weight={!value?.reminders?.some((item: any) => !!item.active) ? undefined : 400}

            color='inherit'
          >
            {reminderName}
          </Type>
        )}

        end={(
          <IconButton
            onClick={onClearReminder}

            size='small'

            className={classNames([
              !value?.reminders?.some((item: any) => !!item.active) && classes.iconInactive
            ])}
          >
            <IconMaterialCloseRounded
              {...iconProps}
            />
          </IconButton>
        )}

        startAlign='center'

        size='small'

        button

        Component='div'

        className={classes.item}
      />
    </Menu>
  );
};

export default Reminders;
