import { Message, Page, Website } from '@amaui/api-utils';
import { Query } from '@amaui/models';
import { IAddMany, IAdditional, IRemoveMany, IResponse, IUpdateMany } from '@amaui/sdk/other';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { AmauiService, AuthService } from 'services';
import { IQuerySubscription } from 'other';

class WebsiteService extends BaseService<Website> {
  public queryWebsitePages = new AmauiSubscription<IQuerySubscription>();

  public constructor() {
    super('websites');

    this.queryObjectsInit(this.queryWebsitePages, 'queryPagePost');
  }

  public async meta(id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.websites.meta(id, options);
  }

  // page
  public async addPage(id: string, body: Page, options?: IAdditional): Promise<IResponse> {
    const response = await AmauiService.sdk.websites.addPage(id, body as any, options);

    AuthService.me();

    return response;
  }

  public async getPage(id: string, page_id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.websites.getPage(id, page_id, options);
  }

  public async queryPage(id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.websites.queryPage(id, options);
  }

  public async queryPagePost(id: string, body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.websites.queryPagePost(id, body, options);
  }

  // messages
  public async addMessage(id: string, id_chat: string, body: Partial<Message>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.websites.addMessage(id, id_chat, body as any, options);
  }

  public async addManyMessage(id: string, id_chat: string, body: IAddMany<Message>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.websites.addManyMessage(id, id_chat, body as any, options);
  }

  public async updateMessage(id: string, id_chat: string, id_message: string, body: Message, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.websites.updateMessage(id, id_chat, id_message, body as any, options);
  }

  public async updateManyMessage(id: string, id_chat: string, body: IUpdateMany<Message>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.websites.updateManyMessage(id, id_chat, body as any, options);
  }

  public async removeMessage(id: string, id_chat: string, id_message: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.websites.removeMessage(id, id_chat, id_message, options);
  }

  public async removeManyMessage(id: string, id_chat: string, body: IRemoveMany<Message>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.websites.removeManyMessage(id, id_chat, body as any, options);
  }

  public async getMessage(id: string, id_chat: string, id_message: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.websites.getMessage(id, id_chat, id_message, options);
  }

  public async queryMessage(id: string, id_chat: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.websites.queryMessage(id, id_chat, options);
  }

}

export default new WebsiteService();
