import React from 'react';

import { Line, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { IMedia } from '@amaui/api-utils';

const useStyle = style(theme => ({
  root: {
    width: '100%',
    maxWidth: 340,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default.primary,
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.07)'
  },

  image: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    aspectRatio: '3 / 2',
    backgroundColor: theme.palette.background.default.primary,
    borderBottom: `1px solid ${theme.palette.light ? '#ededed' : '#373737'}`
  },

  imageOther: {
    backgroundPosition: '50% 85%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '84%',
    width: '100%',
    aspectRatio: '3 / 2',
    backgroundColor: theme.palette.background.primary.tertiary
  },

  main: {
    padding: 20
  },

  name: {
    cursor: 'pointer'
  },

  selected: {
    outline: `2px solid ${theme.palette.text.default.primary}`
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-ItemCardBox' });

export interface IItemCardBox extends IBaseElement {
  object?: IMedia;

  name: string;

  meta?: any;

  image?: string;

  startFooter?: any;

  endFooter?: any;

  selected?: boolean;

  onOpen?: () => any;
}

const Element: React.FC<IItemCardBox> = React.forwardRef((props, ref: any) => {
  const {
    object = {},

    name,

    meta,

    image,

    startFooter,

    endFooter,

    onOpen,

    selected,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  return (
    <Line
      ref={ref}

      gap={0}

      align='unset'

      justify='unset'

      className={classNames([
        className,
        classes.root,
        selected && classes.selected
      ])}

      {...other}
    >
      <Line
        onClick={onOpen}

        className={classNames([
          image ? classes.image : classes.imageOther,
        ])}

        style={{
          backgroundImage: `url(${image})`
        }}
      />

      <Line
        gap={2}

        direction='column'

        flex

        fullWidth

        className={classes.main}
      >
        <Line
          gap={0.5}

          fullWidth
        >
          {name && (
            <Type
              version='t2'

              onClick={onOpen}

              className={classes.name}
            >
              {name}
            </Type>
          )}

          {meta && (
            <Type
              version='b2'
            >
              {meta}
            </Type>
          )}
        </Line>

        <Line
          direction='row'

          align='flex-end'

          justify='space-between'

          flex

          fullWidth
        >
          <Line
            gap={0.5}

            direction='row'

            align='center'
          >
            {startFooter}
          </Line>

          <Line
            gap={0.5}

            direction='row'
          >
            {endFooter}
          </Line>
        </Line>
      </Line>
    </Line>
  );
});

export default Element;
