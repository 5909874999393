import React from 'react';

import { Line, ListItem, Menu } from '@amaui/ui-react';
import { ISelect } from '@amaui/ui-react/Select/Select';
import { classNames, style } from '@amaui/style-react';

import { colorOptions } from 'other';

const useStyle = style(theme => ({
  root: {
    '& .amaui-List-root': {
      maxHeight: 170,
      overflow: 'hidden auto',
      minWidth: 'unset',
      width: 60
    }
  },

  item: {
    cursor: 'pointer'
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-SelectAColor' });

const Element: React.FC<ISelect> = React.forwardRef((props: ISelect, ref: any) => {
  const {
    value,

    onChange,

    multiple,

    noAny,

    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  const options = React.useMemo(() => {
    return [
      ...(!noAny ? [{ name: 'Any color', value: null }] : []),

      ...Object.keys(colorOptions).map((item: any) => colorOptions[item])
    ];
  }, [colorOptions, noAny]);

  const items = React.useMemo(() => {

    return options.map((item: any, index: number) => (
      <ListItem
        key={index}

        start={(
          <Line
            className={classNames([
              classes.palette,
              !item.color && classes.emptyColor
            ])}

            style={{
              background: item.color
            }}
          />
        )}

        startAlign='center'

        selected={item.value === value}

        onClick={() => onChange!(item.value)}

        size='small'

        button
      />
    ))
  }, [options, value, onChange]);

  const selected = options.find((item: any) => item.value === value);

  return (
    <Menu
      menuItems={items}

      AppendProps={{
        offset: [0, 8]
      }}

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Line
        className={classNames([
          classes.item,
          classes.palette,
          classes.emptyColor
        ])}

        style={{
          background: selected?.color
        }}
      />
    </Menu>
  );
});

Element.displayName = 'amaui-app-SelectAColor';

export default Element;
