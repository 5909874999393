import React from 'react';

import { is } from '@amaui/utils';
import { IconButton, Line, ListItem, Tooltip, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { ValidationError } from '@amaui/errors';

import IconMaterialRemove from '@amaui/icons-material-rounded-react/IconMaterialRemoveW100';

import { Button, Input, Inputs, List, ModalForm, SelectRoles, TextField } from 'ui';
import { AppService, UserService } from 'services';
import { getErrorMessage } from 'other';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    onConfirm,

    onClose: onClose_
  } = props;

  const snackbars = useSnackbars();

  const [users, setUsers] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    values: {
      'email': {
        name: 'Email',
        isValid: 'email',
        method: (value) => {
          const exists = refs.users.current.find((item: any) => item?.email === value?.trim());

          if (exists) throw new ValidationError(`You already added invite with this email`);
        },
        required: true
      },

      roles: {
        name: 'Roles',
        is: 'array',
        of: 'string',
        min: 1
      }
    }
  });

  const refs = {
    users: React.useRef<any>(),
    form: React.useRef(form)
  };

  refs.users.current = users;

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });

    if (is('function', onClose_)) onClose_();

    // clear 
    setTimeout(() => {
      refs.form.current.clear();
    }, 1400);
  }, [onClose_]);

  const onAdd = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!(refs.form.current.values.email?.value && valid)) return;

    // add value to users
    setUsers((previous: any) => {
      return [
        ...previous,

        { email: refs.form.current.value.email?.trim() }
      ];
    });

    refs.form.current.onChange('email', null);
  }, []);

  const onAddRoles = React.useCallback((valueNew: any) => {
    refs.form.current.onChange('roles', valueNew);
  }, []);

  const onRemove = React.useCallback((value: number) => {
    setUsers((previous: any) => {
      const usersNew = [...previous];

      usersNew.splice(value, 1);

      return usersNew;
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    await refs.form.current.validate();

    setLoading(true);

    const value = refs.form.current.value;

    const result = await UserService.addMany(
      {
        objects: users.map((item: any) => ({
          ...item,

          ...(value.roles?.length && { roles: value.roles })
        }))
      }
    );

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Employee${users.length > 1 ? 's' : ''} invited!`
      });

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [users, onConfirm, onClose]);

  const iconProps: any = {
    size: 'large'
  };

  const modal: any = {
    write: <>
      <Inputs>
        <Input
          name='Emails'

          description='Invite employees to join your organization using their emails'
        >
          <Line
            fullWidth
          >
            <TextField
              name='Email'

              value={form.values['email'].value || ''}

              onChange={(valueNew: string) => form.onChange('email', valueNew)}

              endVerticalAlign='center'

              end={(
                <Button
                  color='default'

                  version='outlined'

                  onClick={onAdd}
                >
                  Select email
                </Button>
              )}

              fullWidth
            />
          </Line>

          {!!users.length && (
            <List
              paddingVertical='none'

              size='small'
            >
              {users.map((item: any, index: number) => (
                <ListItem
                  key={index}

                  primary={(
                    <Type
                      version='l1'
                    >
                      {item.email}
                    </Type>
                  )}

                  size='small'

                  end={(
                    <Tooltip
                      name='Remove'

                      color='secondary'
                    >
                      <IconButton
                        color='inherit'

                        onClick={() => onRemove(index)}
                      >
                        <IconMaterialRemove {...iconProps} />
                      </IconButton>
                    </Tooltip>
                  )}
                />
              ))}
            </List>
          )}
        </Input>

        <Input
          name='Roles'

          description='When employees accept their invite, they will be automatically assigned these roles'

          fullWidth
        >
          <SelectRoles
            value={refs.form.current.values.roles.value}

            onChange={onAddRoles}

            fullWidth
          />
        </Input>
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      name='Add new employees'

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      NextProps={{
        disabled: !users.length
      }}

      smaller
    />
  );
});

export default Element;
