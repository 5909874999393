import React from 'react';

import { is } from '@amaui/utils';
import { Line, Type } from '@amaui/ui-react';
import { IBaseElement } from '@amaui/ui-react/types';

export interface IInput extends IBaseElement {
  name?: any;

  description?: any;

  iconName?: any;

  iconDescription?: any;

  footer?: any;

  center?: boolean;
}

const Element: React.FC<IInput> = React.forwardRef((props, ref: any) => {
  const {
    name,

    description,

    iconName,

    iconDescription,

    children,

    center,

    footer,

    className,

    ...other
  } = props;

  const withHeader = name || description;

  return (
    <Line
      gap={4}

      fullWidth

      {...other}
    >
      {withHeader && (
        <Line
          gap={0.5}

          fullWidth
        >
          {name && (
            <Line
              gap={1}

              direction='row'

              align='center'
            >
              {iconName}

              {is('string', name) ? (
                <Type
                  version='h3'

                  dangerouslySetInnerHTML={{
                    __html: name
                  }}
                />
              ) : name}
            </Line>
          )}

          {description && (
            <Line
              gap={1}

              direction='row'

              align='center'
            >
              {iconDescription}

              {is('string', description) ? (
                <Type
                  version='b1'

                  weight={200}

                  dangerouslySetInnerHTML={{
                    __html: description
                  }}
                />
              ) : description}
            </Line>
          )}
        </Line>
      )}

      <Line
        gap={4}

        fullWidth
      >
        {children}
      </Line>

      {footer && (
        <Line
          gap={1}

          direction='row'

          align='center'

          justify={center ? 'center' : 'flex-end'}

          fullWidth

          style={{
            marginTop: 10
          }}
        >
          {footer}
        </Line>
      )}
    </Line>
  );
});

export default Element;
