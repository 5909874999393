import React from 'react';

import { cleanValue, getObjectValue, is, textToInnerHTML } from '@amaui/utils';
import { Button, Line, Medias, Properties, Type } from '@amaui/ui-react';
import { AmauiDate, format } from '@amaui/date';

import { booleans, dates, formats, references } from 'other';
import Color from './Color';

const Element = (props: any) => {
  const {
    object,

    values,

    ...other
  } = props;

  const getName = React.useCallback((item: any) => {
    const property = is('object', item) ? item?.property : item;

    let value;

    if (['amaui'].includes(property)) value = property;

    if (['users'].includes(property)) value = 'Employees';

    return (
      <Type
        version='l1'
      >
        {value || (is('object', item) ? item?.name : cleanValue(property, { capitalize: true }))}
      </Type>
    );
  }, []);

  const onOpenMap = React.useCallback((item: any) => {
    const coordinates = item.coordinates;

    if (!coordinates) return;

    window.open(`https://maps.google.com/?q=${coordinates.join(',')}`);
  }, []);

  const getValue = React.useCallback((item: any) => {
    const property = is('array', item) ? item?.property : item;

    const itemValue = item?.value !== undefined ? item?.value : is('function', item?.method) ? item.method(object) : getObjectValue(object, property);

    const typeProps: any = {
      version: 'b1'
    };

    if (['location'].includes(property)) return (
      <Line
        gap={1}

        fullWidth
      >
        <Type
          {...typeProps}
        >
          {object.location?.name}
        </Type>

        <Button
          onClick={() => onOpenMap(object.location)}
        >
          Open map
        </Button>
      </Line>
    );

    if (['reminders'].includes(property)) return (
      <Type
        {...typeProps}
      >
        {object.reminders.length || 0} reminder{object.reminders.length > 1 ? 's' : ''}
      </Type>
    );

    if (['repeat'].includes(property) && object?.repeat?.active && object.repeat.value !== undefined && object.repeat.unit !== undefined) return (
      <Type
        {...typeProps}
      >
        Every {object.repeat.value || 0} {object.repeat.unit}{object.repeat.value > 1 ? 's' : ''}
      </Type>
    );

    if (['image', 'media', 'media_pre', 'media_post'].includes(property)) return (
      <Medias
        values={(is('array', itemValue) ? itemValue : [itemValue]).filter(Boolean)?.map((item: any) => ({
          value: item
        }))}

        noName
      />
    );

    if (references.includes(property)) return (
      <Type
        {...typeProps}
      >
        {(is('array', itemValue) ? itemValue : [itemValue]).filter(Boolean).map((item: any) => item.name?.trim()).filter(Boolean).join(', ')}
      </Type>
    );

    if (booleans.includes(property)) return (
      <Type
        {...typeProps}
      >
        {itemValue ? 'Yes' : 'No'}
      </Type>
    );

    if (dates.includes(property)) return (
      <Type
        {...typeProps}
      >
        {itemValue ? format(new AmauiDate(itemValue), formats.entire) : ''}
      </Type>
    );

    if (property === 'color') return (
      <Color
        color={itemValue}
      />
    );

    if (['note_pre', 'note_post', 'description'].includes(property)) return (
      <Type
        {...typeProps}

        whiteSpace='pre-line'

        dangerouslySetInnerHTML={{
          __html: textToInnerHTML(itemValue)
        }}
      />
    );

    if (property === 'roles') return (
      <Type
        {...typeProps}
      >
        {itemValue?.map((item: any) => cleanValue(item, { capitalize: true }))?.join(', ')}
      </Type>
    );

    if (['users'].includes(property)) return (
      <Type
        {...typeProps}
      >
        {itemValue?.map((item: any) => item?.name)?.join(', ')}
      </Type>
    );

    return (
      <Type
        {...typeProps}
      >
        {[true, undefined].includes(item?.u) ? cleanValue(itemValue || '', { capitalize: true }) : itemValue}
      </Type>
    );
  }, [object]);

  const items = React.useMemo(() => {
    if (!object) return [];

    const valueNew: any = values?.filter((item: any) => {
      const property = is('array', item) ? item?.property : item;

      const itemValue = item?.value !== undefined ? item?.value : is('function', item?.method) ? item.method(object) : getObjectValue(object, property);

      return !(
        itemValue === undefined ||
        (is('string', itemValue) && !itemValue.trim()) ||
        (is('array', itemValue) && !itemValue?.length) ||
        (is('object', itemValue) && !Object.keys(itemValue).length)
      );
    }).map((item: any) => ({
      name: getName(item),
      value: getValue(item)
    }), []);

    return valueNew;
  }, [object, values, getName, getValue]);

  if (!object) return null;

  return (
    <Properties
      rowGap={1.25}

      version='row'

      values={items}

      {...other}
    />
  );
};

export default Element; 
