import React from 'react';

import { capitalize, hash, imageToPalette, is, rgbToHex } from '@amaui/utils';
import { Line, Type } from '@amaui/ui-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { style } from '@amaui/style-react';
import { getMediaUrl } from 'other';
import { SelectMedia } from 'ui';

const useStyles = style(theme => ({
  root: {

  },

  item: {
    width: 77
  },

  color: {
    // Reset
    margin: '0',
    padding: '0',
    border: '0',
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: '1.15',

    width: 77,
    height: 34,
    borderRadius: 8,
    boxShadow: theme.shadows.values.default[1],
    cursor: 'pointer',
    transition: theme.methods.transitions.make('transform'),

    '&:active': {
      transform: 'scale(0.94)'
    },

    '&::-webkit-color-swatch-wrapper': {
      padding: '0'
    },

    '&::-webkit-color-swatch': {
      border: 'none'
    }
  }
}), { name: 'amaui-app-Palette' });

export interface IPaletteValue {
  image: any;
  primary: string;
  secondary: string;
  tertiary: string;
  quaternary: string;
}

export interface IPalette extends IBaseElement {
  value?: IPaletteValue;

  onChange?: (value: IPaletteValue) => any;
}

const Element: React.FC<IPalette> = React.forwardRef((props, ref: any) => {
  const {
    value: valueProps,

    onChange: onChangeProps,

    className,

    ...other
  } = props;

  const { classes } = useStyles();

  const [value, setValue] = React.useState<any>(valueProps || {
    image: '',
    primary: '#ffffff',
    secondary: '#ffffff',
    tertiary: '#ffffff',
    quaternary: '#ffffff'
  });

  const refs = {
    value: React.useRef(value)
  };

  refs.value.current = value;

  React.useEffect(() => {
    if (valueProps && hash(value) !== hash(valueProps)) setValue(valueProps);
  }, [valueProps]);

  const onChange = React.useCallback((valueNew: any) => {
    setValue(valueNew);

    if (is('function', onChangeProps)) onChangeProps!(valueNew);
  }, [onChangeProps]);

  const onChangeColor = React.useCallback((property: string, itemValue: string) => {
    const valueNew = {
      ...refs.value.current,

      [property]: itemValue
    };

    onChange(valueNew);
  }, [onChange]);

  const onImageConfirm = React.useCallback(async (values: any) => {
    const value = values;

    const palette = await imageToPalette(getMediaUrl(value, 480), { amount: 4, size: 140, allowCrossOrigin: true });

    onChange({
      image: value,
      primary: rgbToHex(palette[0]),
      secondary: rgbToHex(palette[1]),
      tertiary: rgbToHex(palette[2]),
      quaternary: rgbToHex(palette[3])
    });
  }, [onChange]);

  const colors = React.useMemo(() => {
    return ['primary', 'secondary', 'tertiary', 'quaternary'];
  }, []);

  return (
    <Line
      gap={2}

      fullWidth
    >
      <Line
        gap={1}

        direction='row'

        wrap='wrap'

        align='flex-start'

        {...other}
      >
        {colors.map((item: any) => (
          <Line
            key={item}

            gap={1}

            align='center'

            className={classes.item}
          >
            <input
              type='color'

              value={value[item]}

              onChange={(event: React.ChangeEvent<any>) => onChangeColor(item, (event.target as any).value)}

              className={classes.color}
            />

            <Line
              gap={0.25}

              align='center'
            >
              <Type
                version='b2'

                weight={400}

                align='center'
              >
                {capitalize(item)}
              </Type>

              <Type
                version='b2'

                align='center'
              >
                {value[item]}
              </Type>
            </Line>
          </Line>
        ))}
      </Line>

      <SelectMedia
        value={value.image}

        onChange={onImageConfirm}

        multiple={false}
      >
        Image to palette
      </SelectMedia>
    </Line>
  );
});

export default Element;
