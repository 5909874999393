import React from 'react';

import { useSnackbars } from '@amaui/ui-react';

import { Button, Input, Paper } from 'ui';
import { UserService } from 'services';
import { getErrorMessage } from 'other';
import config from 'config';

const Element = React.forwardRef(() => {
  const snackbars = useSnackbars();

  const [loading, setLoading] = React.useState<any>(false);
  const [loaded, setLoaded] = React.useState<any>(false);
  const [subscription, setSubscription] = React.useState<PushSubscription>();

  const init = React.useCallback(async () => {
    if (!('serviceWorker' in navigator)) return;

    const registration = await window.navigator.serviceWorker.ready;

    const browserSubscription = await registration.pushManager.getSubscription();

    if (browserSubscription) setSubscription(browserSubscription);

    setLoaded(true);
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onSubscribe = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = 'serviceWorker' in navigator && !subscription;

    if (!valid) return;

    setLoading(true);

    try {
      const registration = await window.navigator.serviceWorker.ready;

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: config.value.webPush.vapid.public_key
      });

      const result = await UserService.pushSubscribe({
        push: {
          value: subscription.toJSON() as any
        }
      });

      if (result.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });
      }
      else {
        setSubscription(subscription);

        snackbars.add({
          primary: `Push notifications turned on, on this device`
        });
      }
    }
    catch (error) {
      console.error('Push notification enable', error);

      snackbars.add({
        color: 'error',
        primary: `Push notifications enable error`
      });
    }

    setLoading(false);
  }, [subscription]);

  const onUnsubscribe = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = 'serviceWorker' in navigator && subscription;

    if (!valid) return;

    setLoading(true);

    try {
      await subscription.unsubscribe();

      const result = await UserService.pushUnsubscribe({
        push: {
          value: subscription.toJSON() as any
        }
      });

      if (result.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });
      }
      else {
        setSubscription(null);

        snackbars.add({
          primary: `Push notifications turned off, on this device`
        });
      }
    }
    catch (error) {
      console.error('Push notification unsubscribe', error);

      snackbars.add({
        color: 'error',
        primary: `Push notifications disable error`
      });
    }

    setLoading(false);
  }, [subscription]);

  return (
    <Paper
      name='Notifications'
    >
      <Input
        name='Push notifications'

        description='Manage push notifications on this device'

        footer={<>
          {loaded && (
            <Button
              version='outlined'

              color='inherit'

              onClick={subscription ? onUnsubscribe : onSubscribe}

              disabled={loading}
            >
              {subscription ? 'Turn off push notifications' : 'Turn on push notifications'}
            </Button>
          )}
        </>}
      />
    </Paper>
  );
});

export default Element;
