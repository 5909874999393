import React from 'react';

import { AutoComplete } from '@amaui/ui-react';
import { IAutoComplete } from '@amaui/ui-react/AutoComplete/AutoComplete';
import { classNames, style } from '@amaui/style-react';

import IconMaterialCloseW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialCloseW100';
import IconMaterialArrowDropDownW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialArrowDropDownW100';

const useStyle = style(theme => ({
  root: {
    '& .amaui-TextField-input': {
      fontSize: '1rem',
      fontWeight: 200
    }
  },

  wrapperRegular: {
    '&.amaui-AutoComplete-wrapper': {
      width: theme.input
    }
  }
}), { name: 'amaui-app-AutoComplete' });

const Element: React.FC<IAutoComplete> = React.forwardRef((props: IAutoComplete, ref: any) => {
  const {
    MenuProps,

    WrapperProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <AutoComplete
      ref={ref}

      version='outlined'

      autoWidth

      size='small'

      IconClear={IconMaterialCloseW100Rounded}

      IconDropdown={IconMaterialArrowDropDownW100Rounded}

      IconButtonProps={{
        size: 'regular'
      }}

      IconProps={{
        size: 'large'
      }}

      MenuProps={{
        portal: true,

        ...MenuProps,

        ListProps: {
          style: {
            maxHeight: 240,
            overflow: 'hidden auto'
          },

          ...MenuProps?.ListProps
        }
      }}

      className={classNames([
        className,
        classes.root
      ])}

      WrapperProps={{
        className: classNames([
          classes.wrapper,
          !other.fullWidth && classes.wrapperRegular
        ]),

        ...WrapperProps
      }}

      {...other}
    />
  );
});

Element.displayName = 'amaui-app-AutoComplete';

export default Element;
