import React from 'react';

import { Line, useSnackbars, useSubscription } from '@amaui/ui-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { classNames } from '@amaui/style-react';

import { getErrorMessage, IQuerySubscription } from 'other';
import { Button } from '.';

export interface IItems extends IBaseElement {
  service: any;

  queryObjectsName?: string;

  stripe?: boolean;
}

const Element: React.FC<IItems> = React.forwardRef((props, ref: any) => {
  const {
    service,

    queryObjectsName = 'queryObjects',

    stripe,

    children,

    ...other
  } = props;

  const snackbars = useSnackbars();

  const queryObjects = useSubscription<IQuerySubscription>(service[queryObjectsName]);

  const [loading, setLoading] = React.useState(false);

  const onLoadMore = React.useCallback(async () => {
    setLoading(true);

    const body: any = {};

    const response = queryObjects?.response;

    if (stripe) body.starting_after = response?.slice(-1)?.[0]?.id;

    const result = await service[queryObjectsName].value!.query({
      query: {
        ...service[queryObjectsName].value!.previousQuery,

        next: service[queryObjectsName].value?.pagination?.next,
        previous: undefined,
        skip: undefined,
        total: undefined,

        ...body,

        loadMore: true
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setLoading(false);
  }, [service, queryObjects, queryObjectsName, stripe]);

  const hasNext = queryObjects?.pagination?.hasNext || queryObjects?.pagination?.has_more;

  return (
    <Line
      ref={ref}

      gap={5}

      direction='column'

      align='center'

      justify='flex-start'

      fullWidth

      className={classNames([
        'amaui-app-Items'
      ])}
    >
      <Line
        ref={ref}

        direction='row'

        align='unset'

        justify='flex-start'

        flex

        fullWidth
      >
        <Line
          ref={ref}

          gap={2}

          direction='row'

          wrap='wrap'

          align='unset'

          justify='center'

          fullWidth

          flex

          {...other}
        >
          {children}
        </Line>
      </Line>

      {hasNext && (
        <Button
          onClick={onLoadMore}

          color='inherit'

          version='outlined'

          size='large'

          disabled={loading}
        >
          Load more
        </Button>
      )}
    </Line>
  );
});

export default Element;
