import React from 'react';

import { copy } from '@amaui/utils';
import { capitalize, classNames, useAmauiTheme } from '@amaui/style-react';
import { Calendar, Checkbox, FormRow, IconButton, Label, Line, List, ListItem, Menu, MenuItem, PaginationItem, Surface, Type, useForm } from '@amaui/ui-react';
import { AmauiDate, format } from '@amaui/date';

import IconMaterialRestartAltRounded from '@amaui/icons-material-rounded-react/IconMaterialRestartAltW100';
import IconMaterialRepeatRounded from '@amaui/icons-material-rounded-react/IconMaterialRepeatW100';
import IconMaterialCloseRounded from '@amaui/icons-material-rounded-react/IconMaterialCloseW100';

import { formats } from 'other';
import { Button, NumericTextField, Select } from 'ui';

const Element = (props: any) => {
  const {
    value,

    onChange,

    minorMenuOpen,

    onMinorMenuOpen,

    onMinorMenuClose,

    formRepeatCustomFromDefault = 'end',

    classes
  } = props;

  const theme = useAmauiTheme();

  const [menuRepeat, setMenuRepeat] = React.useState<any>('default');
  const [menuRepeatEnd, setMenuRepeatEnd] = React.useState<any>('default');

  const formRepeatCustom = useForm({
    values: {
      'repeat': {
        name: 'Repeat',
        value: copy(value?.repeat || { unit: 'day' }),
        is: 'object'
      }
    }
  });

  const formRepeatEnd = useForm({
    values: {
      'date': {
        name: 'Date',
        value: value?.repeat?.ends?.value
      },
      'count': {
        name: 'Count',
        value: (
          value?.repeat?.ends?.version === 'count' ? value?.repeat?.ends?.value > 1e7 ? 4 : value?.repeat?.ends?.value : value?.repeat?.ends?.value < 1e7 ? null : value?.repeat?.ends?.value
        ),
        is: 'number'
      }
    }
  });

  const refs = {
    value: React.useRef(value),
    onChange: React.useRef(onChange),
    repeat: React.useRef<HTMLElement>(),
    repeatEnd: React.useRef<HTMLElement>(),
    formRepeatCustom: React.useRef(formRepeatCustom),
    formRepeatEnd: React.useRef(formRepeatEnd)
  };

  refs.value.current = value;

  refs.onChange.current = onChange;

  refs.formRepeatCustom.current = formRepeatCustom;

  refs.formRepeatEnd.current = formRepeatEnd;

  const reset = React.useCallback(() => {
    setMenuRepeat('default');

    refs.formRepeatCustom.current.clear();
    refs.formRepeatEnd.current.clear();
  }, []);

  React.useEffect(() => {
    // reset 
    if (minorMenuOpen !== 'reminders') reset();
  }, [minorMenuOpen]);

  const onClearRepeat = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();

    onChange('repeat', false, 'active');
  }, [onChange]);

  const onClearRepeatEnds = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();

    onChange('repeat', false, 'ends.active');
  }, [onChange]);

  const onMenuRepeatEndCountClose = React.useCallback(() => {
    setMenuRepeatEnd('default');
  }, []);

  const onMenuRepeatEndCountOkay = React.useCallback(() => {
    onChange([
      ['repeat', true, 'ends.active'],
      ['repeat', 'count', 'ends.version'],
      ['repeat', refs.formRepeatEnd.current.value?.count > 1e7 ? 4 : refs.formRepeatEnd.current.value?.count, 'ends.value']
    ]);

    setTimeout(() => {
      setMenuRepeatEnd('default')
    }, 440);
  }, [onChange]);

  const onMenuRepeatEndDateChange = React.useCallback((valueNew: AmauiDate) => {
    onChange([
      ['repeat', true, 'ends.active'],
      ['repeat', 'date', 'ends.version'],
      ['repeat', valueNew.milliseconds, 'ends.value']
    ]);

    setTimeout(() => {
      setMenuRepeatEnd('default')
    }, 440);
  }, [onChange]);

  const onMenuRepeatCustomOkay = React.useCallback(() => {
    onChange([
      ['repeat', true, 'active'],
      ['repeat', refs.formRepeatCustom.current.value?.repeat?.from || formRepeatCustomFromDefault || 'start', 'from'],
      ['repeat', refs.formRepeatCustom.current.value?.repeat?.unit || 'day', 'unit'],
      ['repeat', refs.formRepeatCustom.current.value?.repeat?.value || 1, 'value'],
      ['repeat', refs.formRepeatCustom.current.value?.repeat?.unit !== 'week' ? [] : refs.formRepeatCustom.current.value?.repeat?.weekdays || [], 'weekdays'],
      ['repeat', refs.formRepeatCustom.current.value?.repeat?.unit !== 'day' ? false : refs.formRepeatCustom.current.value?.repeat?.skip_weekends, 'skip_weekends']
    ]);

    setMenuRepeat('default');

    onMinorMenuClose();
  }, [formRepeatCustomFromDefault, onMinorMenuClose, onChange]);

  const onMenuRepeatCustomClose = React.useCallback(() => {
    setMenuRepeat('default');
  }, []);

  const onMenuRepeatEndClose = React.useCallback(() => {
    setMenuRepeatEnd('default');
  }, []);

  const repeatDefaultItems = React.useMemo(() => {
    return [
      ['repeat', true, 'active'],
      ['repeat', 'every', 'version'],
      ['repeat', formRepeatCustomFromDefault || 'end', 'from'],
      ['repeat', 1, 'value']
    ];
  }, [formRepeatCustomFromDefault]);

  const repeatDefaults = React.useMemo(() => {
    return [
      {
        name: 'Daily',
        selected: value?.repeat?.active && value?.repeat?.unit === 'day' && value?.repeat?.value === 1,
        onClick: () => {
          onChange([
            ...repeatDefaultItems,

            ['repeat', 'day', 'unit']
          ])
        }
      },
      {
        name: 'Weekly',
        selected: value?.repeat?.active && value?.repeat?.unit === 'week' && value?.repeat?.value === 1,
        onClick: () => {
          onChange([
            ...repeatDefaultItems,

            ['repeat', 'week', 'unit']
          ])
        }
      },
      {
        name: 'Monthly',
        selected: value?.repeat?.active && value?.repeat?.unit === 'month' && value?.repeat?.value === 1,
        onClick: () => {
          onChange([
            ...repeatDefaultItems,

            ['repeat', 'month', 'unit']
          ])
        }
      },
      {
        name: 'Yearly',
        selected: value?.repeat?.active && value?.repeat?.unit === 'year' && value?.repeat?.value === 1,
        onClick: () => {
          onChange([
            ...repeatDefaultItems,

            ['repeat', 'year', 'unit']
          ])
        }
      },
      {
        name: 'Custom',
        selected: (
          (value?.repeat?.active && value?.repeat?.value > 1) ||
          (
            value?.repeat?.skip_weekends ||
            !!value?.repeat?.weekdays?.length
          )
        ),
        noMenuCloseOnClick: true,
        onClick: () => {
          formRepeatCustom.onChange('repeat', {
            from: formRepeatCustomFromDefault,

            unit: 'day',

            ...value?.repeat
          });

          setMenuRepeat('custom');
        }
      }
    ].map((item: any, index: number) => (
      <MenuItem
        key={index}

        primary={(
          <Type
            version='b3'
          >
            {item?.name}
          </Type>
        )}

        onClick={item.onClick}

        selected={item.selected}

        size='small'

        button

        menuCloseOnClick={!item.noMenuCloseOnClick}
      />
    ));
  }, [value, formRepeatCustomFromDefault]);

  const repeatEndDefaults = React.useMemo(() => {
    return [
      {
        name: 'End by date',
        selected: value?.repeat?.ends?.active && value?.repeat?.ends?.version === 'date',
        noMenuCloseOnClick: true,
        onClick: () => {
          setMenuRepeatEnd('date');
        }
      },
      {
        name: 'End by count',
        selected: value?.repeat?.ends?.active && value?.repeat?.ends?.version === 'count',
        noMenuCloseOnClick: true,
        onClick: () => {
          setMenuRepeatEnd('count');
        }
      }
    ].map((item: any, index: number) => (
      <MenuItem
        key={index}

        primary={(
          <Type
            version='b3'
          >
            {item?.name}
          </Type>
        )}

        onClick={item.onClick}

        selected={item.selected}

        size='small'

        button

        menuCloseOnClick={!item.noMenuCloseOnClick}
      />
    ));
  }, [value]);

  const onWeekdayToggle = React.useCallback((dayWeekValue: number) => {
    const weekdays = refs.formRepeatCustom.current.values.repeat?.value?.weekdays || [];

    const index = weekdays.findIndex((item: any) => item === dayWeekValue);

    index > -1 ? weekdays.splice(index, 1) : weekdays.push(dayWeekValue);

    refs.formRepeatCustom.current.onChange('repeat', weekdays, 'weekdays');
  }, []);

  const weekDayOptions = React.useMemo(() => {
    return [
      { name: 'M', value: 1 },
      { name: 'T', value: 2 },
      { name: 'W', value: 3 },
      { name: 'T', value: 4 },
      { name: 'F', value: 5 },
      { name: 'S', value: 6 },
      { name: 'S', value: 0 }
    ];
  }, []);

  const selectProps: any = {
    IconButtonProps: {
      size: 'small'
    },
    IconProps: {
      size: 'regular'
    },
    fullWidth: true
  };

  const menusRepeat: any = (methodItem: any) => ({
    default: <>
      <List
        menu

        color='themed'

        size='small'

        fullWidth
      >
        {repeatDefaults.map((item: any, index: number) => (
          React.cloneElement(item, methodItem(item, index))
        ))}
      </List>
    </>,

    custom: <>
      <Surface
        elevation={theme.palette.light ? 2 : 0}

        color='themed'

        className={classes.menuMore}
      >
        <Line
          gap={1.5}

          fullWidth
        >
          <FormRow
            MainProps={{
              gap: 1.5
            }}
          >
            <NumericTextField
              name='How many'

              valueDefault={refs.formRepeatCustom.current.values.repeat?.value?.value || 1}

              onChange={(valueNew: number) => refs.formRepeatCustom.current.onChange('repeat', valueNew, 'value')}

              min={1}

              IconButtonProps={{
                size: 'small'
              }}

              IconProps={{
                size: 'regular'
              }}

              fullWidth
            />

            <Select
              name='Unit'

              value={refs.formRepeatCustom.current.values.repeat?.value?.unit || 'day'}

              onChange={(valueNew: string) => refs.formRepeatCustom.current.onChange('repeat', valueNew, 'unit')}

              MenuProps={{
                portal: false
              }}

              {...selectProps}
            >
              {['day', 'week', 'month', 'year'].map((item: any, index: number) => (
                <ListItem
                  key={index}

                  primary={(
                    <Type
                      version='b3'
                    >
                      {capitalize(item)}
                    </Type>
                  )}

                  value={item}

                  size='small'

                  button
                />
              ))}
            </Select>

            {refs.formRepeatCustom.current.values.repeat?.value?.unit === 'week' && <>
              <Line
                gap={1}

                fullWidth
              >
                <Type
                  version='b3'
                >
                  Weekdays
                </Type>

                <Line
                  gap={0.5}

                  direction='row'

                  align='center'

                  fullWidth
                >
                  {weekDayOptions.map((item: any, index: number) => (
                    <PaginationItem
                      key={index}

                      selected={!!refs.formRepeatCustom.current.values.repeat?.value?.weekdays?.includes(item.value)}

                      onClick={() => onWeekdayToggle(item.value)}

                      size='small'

                      className={classNames([
                        classes.weekday
                      ])}
                    >
                      {item.name}
                    </PaginationItem>
                  ))}
                </Line>
              </Line>
            </>}

            {refs.formRepeatCustom.current.values.repeat?.value?.unit === 'day' && <>
              <Label
                checked={!!refs.formRepeatCustom.current.values.repeat?.value?.skip_weekends}

                onChange={(valueNew: any) => refs.formRepeatCustom.current.onChange('repeat', valueNew, 'skip_weekends')}

                color='default'
              >
                <Checkbox />

                Skip weekends
              </Label>
            </>}
          </FormRow>

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth
          >
            <Button
              version='text'

              size='small'

              onClick={onMenuRepeatCustomClose}
            >
              Close
            </Button>

            <Button
              version='text'

              size='small'

              onClick={onMenuRepeatCustomOkay}
            >
              Ok
            </Button>
          </Line>
        </Line>
      </Surface>
    </>
  });

  const menusRepeatEnd: any = (methodItem: any) => ({
    default: <>
      <List
        menu

        color='themed'

        size='small'

        fullWidth
      >
        {repeatEndDefaults.map((item: any, index: number) => (
          React.cloneElement(item, methodItem(item, index))
        ))}
      </List>
    </>,

    date: <>
      <Surface
        elevation={theme.palette.light ? 2 : 0}

        color='themed'

        className={classes.menuMore}

        style={{
          padding: 0
        }}
      >
        <Line
          gap={1.5}

          fullWidth
        >
          <Calendar
            color='themed'

            valueDefault={value?.repeat?.ends?.version === 'date' ? value?.repeat?.ends?.value ? new AmauiDate(value?.repeat?.ends?.value) : undefined : undefined as any}

            calendarDefault={value?.repeat?.ends?.version === 'date' ? value?.repeat?.ends?.value ? new AmauiDate(value?.repeat?.ends?.value) : undefined : undefined as any}

            onChange={((valueNew: AmauiDate) => onMenuRepeatEndDateChange(valueNew)) as any}

            size='small'
          />
        </Line>
      </Surface>
    </>,

    count: <>
      <Surface
        elevation={theme.palette.light ? 2 : 0}

        color='themed'

        className={classes.menuMore}
      >
        <Line
          gap={1.5}

          fullWidth
        >
          <FormRow
            MainProps={{
              gap: 1
            }}
          >
            <NumericTextField
              name='Count'

              valueDefault={value?.repeat?.ends?.version === 'count' ? refs.formRepeatEnd.current.values['count'].value : undefined}

              onChange={(valueNew: number) => refs.formRepeatEnd.current.onChange('count', valueNew, undefined)}

              min={1}

              max={refs.formRepeatEnd.current?.value?.version === 'count' ? 1e7 : undefined}

              size='small'

              IconButtonProps={{
                size: 'small'
              }}

              IconProps={{
                size: 'regular'
              }}

              fullWidth
            />
          </FormRow>

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth
          >
            <Button
              version='text'

              size='small'

              onClick={onMenuRepeatEndCountClose}
            >
              Close
            </Button>

            <Button
              version='text'

              size='small'

              onClick={onMenuRepeatEndCountOkay}
            >
              Ok
            </Button>
          </Line>
        </Line>
      </Surface>
    </>
  });

  const repeatName = React.useMemo(() => {
    if (value?.repeat?.active && value.repeat.value !== undefined && value.repeat.unit !== undefined) {
      if (value?.repeat?.value === 1) {
        if (value?.repeat?.unit === 'day') return 'Daily';

        if (value?.repeat?.unit === 'week') return 'Weekly';

        if (value?.repeat?.unit === 'month') return 'Monthly';

        if (value?.repeat?.unit === 'year') return 'Yearly';
      }
      else return `${value?.repeat?.value} ${capitalize(value?.repeat?.unit)}s`;
    }

    return 'Repeat';
  }, [value]);

  const iconProps: any = {
    size: 'regular'
  };

  return <>
    {/* Repeat */}
    <Menu
      open={minorMenuOpen === 'repeat'}

      onOpen={() => onMinorMenuOpen('repeat')}

      onClose={onMinorMenuClose}

      name={(methodItem: any) => (
        <Line
          gap={1}

          align='center'

          fullWidth
        >
          {menusRepeat(methodItem)[menuRepeat]}
        </Line>
      )}

      className={classNames([
        classes.minorMenu,
        'amaui-repeat'
      ])}

      ignoreNonExisting

      style={{
        width: refs.repeat?.current?.clientWidth
      }}
    >
      <ListItem
        ref={refs.repeat}

        start={(
          <IconMaterialRepeatRounded
            {...iconProps}
          />
        )}

        primary={(
          <Type
            version={!value?.repeat?.active ? 'b2' : 'l2'}

            weight={!value?.repeat?.active ? undefined : 400}

            color={!value?.repeat?.active ? 'inherit' : 'success'}
          >
            {repeatName}
          </Type>
        )}

        end={(
          <IconButton
            size='small'

            onClick={onClearRepeat}

            className={classNames([
              !value?.repeat?.active && classes.iconInactive
            ])}
          >
            <IconMaterialCloseRounded
              {...iconProps}
            />
          </IconButton>
        )}

        startAlign='center'

        size='small'

        button

        Component='div'

        className={classes.item}
      />
    </Menu>

    {/* Repeat end */}
    {value?.repeat?.active && (
      <Menu
        open={minorMenuOpen === 'repeat-end'}

        onOpen={() => onMinorMenuOpen('repeat-end')}

        onClose={onMenuRepeatEndClose}

        name={(methodItem: any) => (
          <Line
            gap={1}

            align='center'

            fullWidth
          >
            {menusRepeatEnd(methodItem)[menuRepeatEnd]}
          </Line>
        )}

        className={classNames([
          classes.repeatEndMenu,
          'amaui-repeat-end'
        ])}

        ignoreNonExisting

        style={{
          minWidth: refs.repeatEnd.current?.clientWidth
        }}
      >
        <ListItem
          ref={refs.repeatEnd}

          start={(
            <IconMaterialRestartAltRounded
              {...iconProps}
            />
          )}

          primary={(
            <Type
              version='b2'

              color='inherit'
            >
              {!value?.repeat?.ends?.active ? 'No ending' : value?.repeat?.ends?.version === 'count' ? `Ends after ${value?.repeat?.ends.value || 0} repeat${value?.repeat?.ends.value > 1 ? 's' : ''}` : `Ends after ${format(new AmauiDate(value?.repeat?.ends.value), formats.date)}`}
            </Type>
          )}

          end={(
            <IconButton
              size='small'

              onClick={onClearRepeatEnds}

              className={classNames([
                !value?.repeat?.ends?.active && classes.iconInactive
              ])}
            >
              <IconMaterialCloseRounded
                {...iconProps}
              />
            </IconButton>
          )}

          startAlign='center'

          size='small'

          button

          Component='div'

          className={classes.item}
        />
      </Menu>
    )}
  </>;
};

export default Element;
