import React from 'react';

import { is } from '@amaui/utils';
import { Line, Type } from '@amaui/ui-react';
import { IBaseElement, ISize } from '@amaui/ui-react/types';

export interface IInput extends IBaseElement {
  name?: any;

  description?: any;

  iconName?: any;

  iconDescription?: any;

  footer?: any;

  startHeader?: any;

  endHeader?: any;

  size?: ISize;
}

const Element: React.FC<IInput> = React.forwardRef((props, ref: any) => {
  const {
    name,

    description,

    size = 'regular',

    iconName,

    iconDescription,

    children,

    startHeader,

    endHeader,

    footer,

    className,

    ...other
  } = props;

  const withHeader = name || description;

  return (
    <Line
      gap={2.25}

      fullWidth

      {...other}
    >
      {withHeader && (
        <Line
          gap={0.5}

          fullWidth
        >
          {name && (
            <Line
              gap={2}

              direction='row'

              align='center'

              fullWidth
            >
              {startHeader}

              <Line
                gap={1}

                direction='row'

                align='center'
              >
                {iconName}

                {is('string', name) ? (
                  <Type
                    version={size === 'large' ? 'h3' : size === 'regular' ? 't1' : 't2'}

                    dangerouslySetInnerHTML={{
                      __html: name
                    }}
                  />
                ) : name}
              </Line>

              {endHeader}
            </Line>
          )}

          {description && (
            <Line
              gap={1}

              direction='row'

              align='center'
            >
              {iconDescription}

              {is('string', description) ? (
                <Type
                  version={size === 'large' ? 'b1' : size === 'regular' ? 'b2' : 'b3'}

                  weight={200}

                  dangerouslySetInnerHTML={{
                    __html: description
                  }}
                />
              ) : description}
            </Line>
          )}
        </Line>
      )}

      <Line
        gap={1.5}

        fullWidth
      >
        {children}
      </Line>

      {footer && (
        <Line
          gap={1}

          direction='row'

          align='center'

          justify='flex-end'

          fullWidth

          style={{
            marginTop: 10
          }}
        >
          {footer}
        </Line>
      )}
    </Line>
  );
});

export default Element;
