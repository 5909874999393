import { Organization } from '@amaui/api-utils';
import { IAdditional, IOrganizationsAdd, IOrganizationsUpdate, IResponse, IStripePaymentMethodAttach, IStripePaymentMethodDettach, IStripePaymentMethodUpdate, IStripeQuery, ISubscriptionUpdate } from '@amaui/sdk/other';
import { Query } from '@amaui/models';
import AmauiSubscription from '@amaui/subscription';

import { AmauiService, AuthService } from 'services';
import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'other';

class OrganizationService extends BaseService<Organization> {
  public queryUsers = new AmauiSubscription<IQuerySubscription>();
  public selectedUsers = new AmauiSubscription<ISelectedSubscription>();
  public queryInvoices = new AmauiSubscription<IQuerySubscription>();
  public selectedInvoices = new AmauiSubscription<ISelectedSubscription>();
  public queryPaymentMethods = new AmauiSubscription<IQuerySubscription>();
  public selectedPaymentMethods = new AmauiSubscription<ISelectedSubscription>();
  public queryUsersChat = new AmauiSubscription<IQuerySubscription>();

  public constructor() {
    super('organizations');

    this.queryObjectsInit(this.queryUsers, 'queryUsersPost');

    this.selectedObjectsInit(this.selectedUsers);

    this.queryObjectsInit(this.queryInvoices, 'queryInvoicePost');

    this.selectedObjectsInit(this.selectedInvoices);

    this.queryObjectsInit(this.queryPaymentMethods, 'queryPaymentMethodPost');

    this.selectedObjectsInit(this.selectedPaymentMethods);

    this.queryObjectsInit(this.queryUsersChat, 'queryUsersPost');
  }

  public async add(body: IOrganizationsAdd, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.add(body, options);
  }

  public async update(id: string, body: IOrganizationsUpdate, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.update(id, body, options);
  }

  public async remove(options?: any) {
    return AmauiService.sdk.organizations.remove(options);
  }

  public async queryUsersPost(body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.queryUsersPost(body, options);
  }

  public async getUser(id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.getUser(id, options);
  }

  public async updateUser(id: string, body: any, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.updateUser(id, body, options);
  }

  public async removeUser(id: string, options?: IAdditional): Promise<IResponse> {
    const response = await AmauiService.sdk.organizations.removeUser(id, options);

    AuthService.me();

    return response;
  }

  // stripe
  // subscription
  public async getSubscription(options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.getSubscription(options);
  }

  public async updateSubscription(body?: ISubscriptionUpdate): Promise<IResponse> {
    const response = await AmauiService.sdk.organizations.updateSubscription(body);

    AuthService.me();

    return response;
  }

  // setup intent
  public async addSetupIntent(body?: any): Promise<IResponse> {
    return AmauiService.sdk.organizations.addSetupIntent(body);
  }

  // payment method
  public async queryPaymentMethod(options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.queryPaymentMethod(options);
  }

  public async queryPaymentMethodPost(body?: any, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.queryPaymentMethodPost(body, options);
  }

  public async getPaymentMethod(id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.getPaymentMethod(id, options);
  }

  public async updatePaymentMethod(id: string, body: IStripePaymentMethodUpdate, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.updatePaymentMethod(id, body, options);
  }

  public async attachPaymentMethod(body: IStripePaymentMethodAttach, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.attachPaymentMethod(body, options);
  }

  public async dettachPaymentMethod(body: IStripePaymentMethodDettach, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.dettachPaymentMethod(body, options);
  }

  // invoice
  public async queryInvoice(body?: IStripeQuery): Promise<IResponse> {
    return AmauiService.sdk.organizations.queryInvoice(body);
  }

  public async queryInvoicePost(body?: IStripeQuery, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.queryInvoicePost(body, options);
  }

  public async getInvoice(id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.organizations.getInvoice(id, options);
  }

}

export default new OrganizationService();
