import React from 'react';
import audioFix from 'webm-duration-fix';

import { debounce, hash, innerHTMLToText, textToInnerHTML } from '@amaui/utils';
import { Avatar, Checkbox, Expand, Form, FormRow, IconButton, Line, ListItem, Medias, Menu, MenuItem, Tooltip, Type, useForm, useSnackbars, useSubscription } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { AmauiDate, add, format, is as Is, remove, startOf } from '@amaui/date';
import { IMedia, Task } from '@amaui/api-utils';

import IconMaterialAccountCircleRounded from '@amaui/icons-material-rounded-react/IconMaterialAccountCircleW100';
import IconMaterialFolderOpenRounded from '@amaui/icons-material-rounded-react/IconMaterialFolderOpenW100';
import IconMaterialArrowForwardIosRounded from '@amaui/icons-material-rounded-react/IconMaterialArrowForwardIosW100';
import IconMaterialFlagFilledRounded from '@amaui/icons-material-rounded-react/IconMaterialFlagW100Filled';
import IconMaterialCalendarTodayRounded from '@amaui/icons-material-rounded-react/IconMaterialCalendarTodayW100';

import { AuthService, MediaService, OrganizationService, TaskService } from 'services';
import { ISignedIn, formats, getElementText, getErrorMessage, mediasToValue } from 'other';
import { AutoCompleteObjects, SmartTextField, ObjectCalendar, useLibrary, SpeechToText, TextToSpeech, AudioRecorder } from 'ui';

const useStyle = style(theme => ({
  root: {
    padding: '10px 24px 20px',
    overflow: 'hidden',
    maxWidth: '100vw'
  },

  main: {
    height: 0,
    overflowY: 'auto'
  },

  endDate: {
    '&.amaui-ListItem-root': {
      padding: '2px 4px'
    }
  },

  endDateWrapper: {
    '&.amaui-ListItem-wrapper': {
      width: 'auto'
    }
  },

  footer: {
    overflowY: 'hidden',
    overflowX: 'auto'
  }
}), { name: 'amaui-app-Task' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onRemove,

    onConfirm,

    onClose,

    modal,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const media = useLibrary();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [object] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);
  const [loaded, setLoaded] = React.useState(false);
  const [openAssignee, setOpenAssignee] = React.useState(false);

  const form = useForm({
    values: {
      name: {
        name: 'Name',
        is: 'string',
        value: object?.name ? textToInnerHTML(object?.name) : '',
        min: 1,
        max: 1400
      },
      'value': {
        name: 'Description',
        value: object?.value?.[0]?.value ? textToInnerHTML(object?.value?.[0]?.value) : '',
        max: 14000,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 14000 characters'
        }
      },
      done: {
        name: 'Done',
        value: object?.done,
        is: 'boolean'
      },
      'starts_at': {
        name: 'Starts at date',
        is: 'number',
        value: object?.starts_at
      },
      'ends_at': {
        name: 'Due date',
        is: 'number',
        value: object?.ends_at
      },
      'priority.name': {
        name: 'Priority',
        value: object?.priority?.name || 'default',
        is: 'string',
        in: ['default', 'low', 'medium', 'important']
      },
      'priority.value': {
        name: 'Priority value',
        value: object?.priority?.value || 1,
        is: 'number',
        min: 1,
        max: 1000
      },
      'repeat': {
        name: 'Repeat',
        value: object?.repeat || {},
        is: 'object'
      },
      'reminders': {
        name: 'Reminders',
        value: [],
        is: 'array',
        of: 'object'
      },
      'media': {
        name: 'Media',
        value: [...object?.media || []],
        is: 'array',
        of: 'object'
      },
      'assignee': {
        name: 'Assignee',
        value: object?.assignee?.[0]
      }
    }
  });

  const refs = {
    wrapper: React.useRef<HTMLElement>(),
    name: React.useRef<HTMLElement>(),
    value: React.useRef<HTMLElement>(),
    form: React.useRef<any>(),
    object: React.useRef<any>(),
    onConfirm: React.useRef<any>(),
    original: React.useRef<any>(),
    updateInternal: React.useRef(true)
  };

  refs.form.current = form;

  refs.object.current = object;

  refs.onConfirm.current = onConfirm;

  const onToggleAssignee = React.useCallback(() => {
    setOpenAssignee(previous => !previous);
  }, []);

  // const onOpenAssignee = React.useCallback(() => {
  //   setOpenAssignee(true);
  // }, []);

  // const onCloseAssignee = React.useCallback(() => {
  //   setOpenAssignee(false);
  // }, []);

  const init = React.useCallback(() => {
    setLoaded(false);

    setOpenAssignee(false);

    // Only for when updating a task outside the UpdateTask modal 
    // to update here as well 
    refs.name.current!.innerHTML = textToInnerHTML(refs.object.current?.name || '');

    refs.value.current!.innerHTML = textToInnerHTML(refs.object.current?.value?.[0]?.value || refs.object.current?.value?.[0]?.elements || '');

    refs.form.current.onChange([
      ['name', refs.object.current?.name || ''],
      ['value', refs.object.current?.value?.[0]?.value || ''],
      ['done', refs.object.current?.done],
      ['repeat', refs.object.current?.repeat || {}],
      ['reminders', refs.object.current?.reminders || []],
      ['priority.name', refs.object.current?.priority?.name],
      ['priority.value', refs.object.current?.priority?.value],
      ['starts_at', refs.object.current?.starts_at],
      ['ends_at', refs.object.current?.ends_at],
      ['media', [...(refs.object.current.media || [])]],
      ['assignee', refs.object.current?.assignee?.[0]]
    ]);

    setTimeout(() => {
      setLoaded(true);
    }, 140);

    // two-way binding 
    // from tasks page, to task form 
    TaskService.task.subscribe((item: any) => {
      if (item.internal) return;

      if (item?.id === refs.object.current?.id) {
        refs.updateInternal.current = false;

        if (item.name !== undefined) refs.name.current!.innerHTML = textToInnerHTML(item?.name || '');

        const updateValues = [];

        if (item?.name !== undefined) updateValues.push(['name', item?.name]);

        if (item?.value !== undefined) updateValues.push(['value', item?.value]);

        if (item?.done !== undefined) updateValues.push(['done', item?.done]);

        if (item?.repeat !== undefined) updateValues.push(['repeat', item?.repeat]);

        if (item?.reminders !== undefined) updateValues.push(['reminders', item?.reminders]);

        if (item?.['priority.name'] !== undefined) updateValues.push(['priority.name', item?.['priority.name']]);

        if (item?.['priority.value'] !== undefined) updateValues.push(['priority.value', item?.['priority.value']]);

        if (item?.starts_at !== undefined) updateValues.push(['starts_at', item?.starts_at]);

        if (item?.ends_at !== undefined) updateValues.push(['ends_at', item?.ends_at]);

        if (item?.media !== undefined) updateValues.push(['media', item?.media]);

        if (item?.assignee !== undefined) updateValues.push(['assignee', item?.assignee]);

        if (updateValues.length) refs.form.current.onChange(updateValues);
      }
    });
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, [object]);

  const getValue = React.useCallback(() => {
    const value = refs.form.current.value;

    const body: Partial<Task> = {
      name: value.name,
      done: value.done,
      starts_at: value.starts_at,
      ends_at: value.ends_at,
      repeat: value.repeat,
      reminders: value.reminders,
      priority: value.priority,
      media: value.media
    };

    if (value.value !== undefined) {
      body.value = [
        {
          version: 'type',
          value: innerHTMLToText(value.value)
        }
      ];
    }

    if (value.assignee !== undefined) {
      if (value.assignee?.id) body.assignee = [
        {
          id: value.assignee?.id,
          name: value.assignee?.name
        }
      ];
      else body.assignee = [];
    };

    body.reminders?.forEach((reminder: any) => {
      if (reminder?.remind_at) delete reminder.remind_at;
    });

    return body;
  }, []);

  const onUpdate = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    const object_ = refs.object.current;

    setLoading(true);

    const body = getValue();

    const result = await TaskService.update(object_?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      // if done or ends_at, repeat changed 
      // refetch 
      if (object_ && hash({ done: object_.done, ends_at: object_.ends_at, repeat: object_.repeat }) !== hash({ done: body.done, ends_at: body.ends_at, repeat: body.repeat })) TaskService.refetch();
    }

    setLoading(false);
  }, []);

  const onAudioRecorderConfirm = React.useCallback(async (value: Blob) => {
    setLoading('voice recording');

    const media = await audioFix(value);

    // validate
    // 140 mb maximum
    if (media.size > (140 * 1e6)) {
      snackbars.add({
        color: 'error',
        primary: `Maximum allowed file size is 140 mb`
      });

      return;
    }

    const name = `Voice recording, ${format(new AmauiDate(), formats.entire)}`;

    // meta
    const meta: any = {};

    const result = await MediaService.add({
      name,

      meta,

      app: 'task',

      // media
      media
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const mediaMongo = result?.response?.response;

      // Update the task
      const medias = refs.form.current.values.media.value || [];

      medias.push(mediasToValue({
        id: mediaMongo?.id,
        name,
        mime: media.type,
        meta: mediaMongo?.meta,
        versions: mediaMongo?.versions,
        added_at: AmauiDate.milliseconds
      }));

      refs.form.current.onChange('media', medias);
    }

    setLoading(false);
  }, [onConfirm]);

  const onDone = React.useCallback(() => {
    if (!form.values.done.value) {
      form.onChange([
        ['done', !form.values.done.value],
        // ['repeat', false, 'active']
      ]);
    }
    else form.onChange('done', !form.values.done.value);
  }, [form]);

  const onUpdateDebounced = React.useCallback(debounce(onUpdate, 1400), []);

  const onAddMediaConfirm = React.useCallback((value: IMedia[]) => {
    const valueNew = [...value].map(media => mediasToValue({
      id: media.id,
      name: media.name,
      mime: media.mime,
      meta: media.meta,
      versions: media.versions,
      thumbnails: media.thumbnails,
      added_at: AmauiDate.milliseconds
    }));

    form.onChange('media', valueNew);
  }, [form]);

  const onOpenMedia = React.useCallback(() => {
    media.open({
      selected: (form.values['media']?.value || []).filter(Boolean),
      multiple: true,
      onConfirm: onAddMediaConfirm
    });
  }, [media, onAddMediaConfirm]);

  const body = {
    ...refs.form.current.value
  };

  if (body.value !== undefined) {
    body.value = [
      {
        version: 'type',
        value: innerHTMLToText(body.value)
      }
    ];
  }

  if (body.assignee !== undefined) {
    if (body.assignee?.id) body.assignee = [
      {
        id: body.assignee?.id,
        name: body.assignee?.name
      }
    ];
    else body.assignee = [];
  };

  const updated = hash(body);

  const updateService = React.useCallback(async () => {
    await refs.form.current.validate();

    const body = getValue();

    TaskService.queryObjects.value!.updateObject(refs.object.current?.id, body);

    // ie. for TaskLists 
    TaskService.task.emit({
      id: refs.object.current?.id,

      ...body,

      internal: true
    } as any);
  }, []);

  React.useEffect(() => {
    if (loaded) {
      const updateInternal = refs.updateInternal.current;

      refs.updateInternal.current = true;

      if (!updateInternal) return;

      // update 
      updateService();

      onUpdateDebounced();
    }
  }, [updated]);

  let valueDate: string = 'Due date';

  const dates: any = {};

  const valueForm = loaded ? form.value : object;

  if (valueForm.ends_at) {
    const now = new AmauiDate();
    const ends = new AmauiDate(valueForm.ends_at);

    valueDate = format(ends, 'DD MMM');

    const starts = {
      now: startOf(now, 'day'),
      ends: startOf(ends, 'day')
    };

    dates.inThePast = Is(starts.ends, 'before', starts.now);

    dates.yesterday = format(remove(1, 'day', now), 'DD MMM YYYY') === format(ends, 'DD MMM YYYY');

    dates.today = format(now, 'DD MMM YYYY') === format(ends, 'DD MMM YYYY');

    dates.tomorrow = format(add(1, 'day', now), 'DD MMM YYYY') === format(ends, 'DD MMM YYYY');

    if (dates.yesterday) valueDate = `Yesterday, ${valueDate}`;

    if (dates.today) valueDate = `Today, ${valueDate}`;

    if (dates.tomorrow) valueDate = `Tomorrow, ${valueDate}`;
  }

  const color = !valueForm.ends_at ? 'default' : !dates.inThePast ? 'success' : 'error';

  const priorityToColor = (value: any) => {
    if (value === 'low') return 'info';

    if (value === 'medium') return 'warning';

    if (value === 'important') return 'error';

    return 'primary';
  };

  const iconProps: any = {
    size: 'large'
  };

  const getMenuItemsPriority = () => {
    const values = [
      { name: 'Default', onClick: () => { form.onChange([['priority.name', 'default'], ['priority.value', 1]]); } },
      { name: 'Low', onClick: () => { form.onChange([['priority.name', 'low'], ['priority.value', 2]]); } },
      { name: 'Medium', onClick: () => { form.onChange([['priority.name', 'medium'], ['priority.value', 3]]); } },
      { name: 'Important', onClick: () => { form.onChange([['priority.name', 'important'], ['priority.value', 4]]); } }
    ];

    return values.map((item: any, index: number) => (
      <MenuItem
        key={index}

        start={(
          <IconMaterialFlagFilledRounded
            color={priorityToColor(item.name?.toLowerCase())}

            {...iconProps}
          />
        )}

        primary={(
          <Type
            version='b3'
          >
            {item.name}
          </Type>
        )}

        size='small'

        onClick={item.onClick}

        menuCloseOnClick

        button
      />
    ));
  };

  const propsOther: any = {
    size: 'regular'
  };

  let assigneeElement = (
    <IconButton
      color='inherit'

      onClick={onToggleAssignee}

      {...propsOther}
    >
      <IconMaterialAccountCircleRounded
        {...iconProps}
      />
    </IconButton>
  );

  if (form.values.assignee.value?.id) assigneeElement = (
    <Avatar
      tonal

      color='secondary'

      onClick={onToggleAssignee}

      {...propsOther}
    >
      {form.values.assignee.value?.name?.slice(0, 1)}
    </Avatar>
  );

  const onSpeechToTextStart = React.useCallback(() => {
    refs.original.current = textToInnerHTML(refs.form.current.values.value.value);
  }, []);

  const onSpeechToTextStop = React.useCallback(() => {
    refs.original.current = '';
  }, []);

  const onSpeechToTextUpdate = React.useCallback((valueNew: any) => {
    refs.value.current!.innerHTML = refs.original.current;

    const div = window.document.createElement('div');

    div.innerHTML = valueNew;

    refs.value.current!.append(div);

    refs.form.current.onChange('value', innerHTMLToText(refs.value.current!.innerHTML));
  }, []);

  return (
    <Form
      gap={2}

      onSubmit={onUpdate}

      fullWidth

      flex

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Line
        ref={refs.wrapper}

        justify='space-between'

        fullWidth

        flex
      >
        <Line
          gap={1}

          direction='row'

          align='center'

          justify='space-between'

          fullWidth
        >
          <Checkbox
            checked={!!form.values.done.value}

            color={priorityToColor(form.values['priority.name'].value?.toLowerCase())}

            onClick={onDone}
          />

          <ObjectCalendar
            value={{
              id: object?.id,

              ...form.value
            }}

            onRemove={onRemove}

            onChange={form.onChange}

            closeOnChange={false}

            RepeatProps={{
              fromOptions: ['end', 'done']
            }}
          >
            <ListItem
              primary={(
                <Type
                  version='l2'

                  color={color}

                  align='center'
                >
                  {valueDate}
                </Type>
              )}

              start={(
                <IconMaterialCalendarTodayRounded
                  color={color}

                  {...iconProps}
                />
              )}

              startAlign='center'

              size='small'

              button

              noBackground

              Component='div'

              WrapperProps={{
                className: classes.endDateWrapper
              }}

              RootProps={{
                className: classes.endDate
              }}
            />
          </ObjectCalendar>

          <Menu
            menuItems={getMenuItemsPriority()}
          >
            <IconButton >
              <IconMaterialFlagFilledRounded
                color={priorityToColor(form.values['priority.name'].value)}

                {...iconProps}
              />
            </IconButton>
          </Menu>
        </Line>

        <Line
          gap={1}

          direction='column'

          align='unset'

          justify='unset'

          flex

          fullWidth

          className={classes.main}
        >
          <SmartTextField
            ref={refs.name}

            placeholder='Name'

            valueDefault={form.values.name.value}

            onChange={(valueNew: string) => form.onChange('name', valueNew)}

            additional={{
              version: 't2'
            }}

            style={{
              whiteSpace: 'normal'
            }}
          />

          <SmartTextField
            ref={refs.value}

            placeholder='Description'

            valueDefault={form.values.value.value}

            onChange={(valueNew: string) => form.onChange('value', valueNew)}

            additional={{
              version: 'b1'
            }}

            edit

            multiline

            mention
          />

          <Medias
            values={form.values.media.value?.map((item: any) => ({
              value: item
            }))}
          />

          <Expand
            in={openAssignee}

            parent={refs.wrapper.current as any}
          >
            <FormRow
              fullWidth
            >
              <AutoCompleteObjects
                name='Users'

                value={form.values.assignee.value?.id ? { ...form.values.assignee.value, value: form.values.assignee.value.id } : null}

                valueInputDefault={form.values.assignee.value?.name}

                onChange={(valueNew: any) => form.onChange('assignee', valueNew)}

                service={OrganizationService}

                method='queryUsersPost'

                fullWidth
              />
            </FormRow>
          </Expand>
        </Line>

        {/* footer */}
        <Line
          direction='row'

          align='center'

          justify='space-between'

          fullWidth

          className={classes.footer}
        >
          <Line
            gap={1}

            direction='row'

            align='center'

            style={{
              flex: '0 0 auto'
            }}
          >
            {!modal && (
              <Tooltip
                name='Close'
              >
                <IconButton
                  color='inherit'

                  onClick={onClose}

                  {...propsOther}
                >
                  <IconMaterialArrowForwardIosRounded
                    {...iconProps}
                  />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip
              name='Assignee'
            >
              {assigneeElement}
            </Tooltip>
          </Line>

          <Line
            gap={1}

            direction='row'

            align='center'

            style={{
              flex: '0 0 auto'
            }}
          >
            <SpeechToText
              onData={onSpeechToTextUpdate}

              onChange={onSpeechToTextUpdate}

              onStart={onSpeechToTextStart}

              onStop={onSpeechToTextStop}

              language={signedIn?.organization?.personalization?.settings?.languages?.speech}
            />

            {!!form.values.value.value?.length && (
              <TextToSpeech
                read={getElementText(form.values.value.value)}

                language={signedIn?.organization?.personalization?.settings?.languages?.speech}
              />
            )}

            <AudioRecorder
              onConfirm={(value: Blob) => onAudioRecorderConfirm(value)}

              disabled={loading === 'voice recording'}
            />

            <Tooltip
              name='Add media'
            >
              <IconButton
                onClick={onOpenMedia}

                {...propsOther}
              >
                <IconMaterialFolderOpenRounded
                  {...iconProps}
                />
              </IconButton>
            </Tooltip>
          </Line>
        </Line>
      </Line>
    </Form>
  );
});

export default Element;
