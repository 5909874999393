import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { copy, equalDeep, getID } from '@amaui/utils';
import { Line, Reset, useMediaQuery, useQuery } from '@amaui/ui-react';
import { IAmauiTheme, classNames, colors, style, useAmauiTheme } from '@amaui/style-react';
import { User } from '@amaui/api-utils';

import { Meta, useMainLoader, useSubscription } from 'ui';
import { AuthService, AmauiService, StorageService, UserService, SocketService } from 'services';
import { ISignedIn, addStyle, getSettings } from 'other';
import Routes from './Routes';
import config from 'config';

// amaui 
(window as any).amaui_app = true;

const useStyle = style(theme => ({
  '@p': {
    '*': {
      fontOpticalSizing: 'auto',

      '&::selection': {
        color: theme.methods.palette.color.value('primary', 10),
        backgroundColor: theme.methods.palette.color.value('primary', 80),
      },

      '&[contenteditable]:empty:before': {
        color: 'inherit',
        content: 'attr(data-placeholder)',
        display: 'block',
        fontFamily: 'inherit',
        fontStyle: 'inherit',
        fontWeight: 'inherit',
        opacity: 0.5,
        fontSize: '1rem'
      }
    },

    body: {
      fontFamily: theme.typography.font_family.secondary,
      color: theme.methods.palette.color.value('primary', 10),
      backgroundColor: theme.palette.light ? theme.palette.color.primary[99] : theme.palette.color.primary[5],
      height: '100vh',
      overflow: 'hidden',

      '&::before': {
        content: "''",
        background: '#dcfefb',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        backgroundImage: 'radial-gradient(circle at top left, hsl(60deg 100% 50%), transparent 400px), radial-gradient(circle at top right, #ffb200, transparent 30%), radial-gradient(at bottom left, #00ffe9, transparent 40%), radial-gradient(at bottom right, #9b00ff, transparent 400px), radial-gradient(at 60% 57%, #00ff99, transparent 44%)',
        opacity: 0.1
      },

      '& .amaui-overflow-y': {
        flex: '1 1 auto',
        height: 0,
        overflow: 'hidden auto'
      },

      '& .amaui-overflow-x': {
        paddingBlock: 6,
        overflow: 'auto hidden'
      },

      '& .amaui-Label-helper-text, & .amaui-TextField-helper-text': {
        ...theme.typography.values.b2
      },

      '& .amaui-Snackbar-root': {
        padding: '10px 10px 10px 32px',
        borderRadius: 0,

        '& .amaui-Snackbar-primary': {
          '& > .amaui-Type-root': {
            fontSize: '1rem',
            fontWeight: 300
          }
        }
      },

      '& .amaui-DateRangePicker-root': {
        '& .amaui-TextField-input, & .amaui-TextField-label': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .amaui-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        }
      },

      '& .amaui-TextField-root': {
        '& .amaui-TextField-input, & .amaui-TextField-label': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .amaui-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        }
      },

      '& .amaui-Select-root': {
        '& .amaui-Select-input, & .amaui-TextField-label': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .amaui-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        },

        '& .amaui-ListItem-text-primary': {
          whiteSpace: 'nowrap'
        }
      },

      '& .amaui-DatePicker-root:not(.amaui-full-width), & .amaui-TextField-wrapper:not(.amaui-full-width), & .amaui-TextField-root:not(.amaui-full-width), & .amaui-Select-wrapper:not(.amaui-full-width), & .amaui-Select-root:not(.amaui-full-width), & .amaui-TextField-root:not(.amaui-full-width), & .amaui-AutoCompleteGooglePlaces-root:not(.amaui-full-width), & .amaui-AutoComplete-wrapper:has(.amaui-AutoCompleteGooglePlaces-root):not(.amaui-full-width), & .amaui-AutoCompleteCurrency-root:not(.amaui-full-width), & .amaui-AutoComplete-wrapper:has(.amaui-AutoCompleteCurrency-root):not(.amaui-full-width)': {
        width: theme.input,
        height: 50,
        flex: '0 0 auto'
      },

      '& .amaui-AutoComplete-wrapper.amaui-full-width': {
        '& .amaui-AutoComplete-root': {
          width: '100%'
        }
      },

      '& .amaui-Type-root': {
        '&.amaui-Type-version-b1, &.amaui-Type-version-b2, &.amaui-Type-version-b3': {
          fontWeight: 200
        }
      },

      '& .amaui-Modal-surface': {
        '&.amaui-Surface-root': {
          borderRadius: 0,
          background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
        }
      },

      '& .amaui-Modal-background:not(.amaui-Modal-background-invisible)': {
        background: theme.methods.palette.color.colorToRgb(theme.methods.palette.color.value('default', 10), 7)
      },

      '& .amaui-Tabs-root.amaui-Surface-root': {
        background: 'transparent',
        minHeight: 'unset'
      },

      '& .amaui-Page-root': {
        maxWidth: 'unset'
      },

      '& .amaui-mention': {
        ...theme.typography.values.l2,

        fontSize: 'inherit',
        fontWeight: 400,
        cursor: 'pointer',
        userSelect: 'none',
        color: theme.methods.palette.color.value('primary', 30)
      },

      '& .amaui-ModalHeader-root': {
        paddingBottom: 0
      },

      '& .amaui-Tooltip-name-root': {
        '& > .amaui-DateTimePicker-main, & > .amaui-DatePicker-main, & > .amaui-TimePicker-main, & > .amaui-Calendar-root': {
          boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)'
        },

        '& > .amaui-List-root': {
          // maxWidth: 'unset',
          // width: '100%'
        }
      },

      '& .amaui-SmartTextField-root[data-placeholder="Description"]': {
        margin: '0 0 20px',
        overflow: 'hidden auto',

        ...theme.typography.values.b1
      }
    },

    a: {
      color: 'var(--amaui-palette-color-primary-30)'
    },

    '.amaui-logo-amaui': {
      color: (theme.palette.color as any).amaui.main,
      fill: (theme.palette.color as any).amaui.main
    },

    '.amaui-logo-personal-trainer': {
      color: (theme.palette.color as any)['personal-trainer'].main,
      fill: (theme.palette.color as any)['personal-trainer'].main
    },

    '.amaui-logo-website': {
      color: (theme.palette.color as any).website.main,
      fill: (theme.palette.color as any).website.main
    },

    '.amaui-logo-task': {
      color: (theme.palette.color as any).task.main,
      fill: (theme.palette.color as any).task.main
    },

    '.amaui-logo-note': {
      color: (theme.palette.color as any).note.main,
      fill: (theme.palette.color as any).note.main
    },

    '.amaui-logo-url-shortener': {
      color: (theme.palette.color as any)['url-shortener'].main,
      fill: (theme.palette.color as any)['url-shortener'].main
    },

    '.amaui-logo-blog': {
      color: (theme.palette.color as any).blog.main,
      fill: (theme.palette.color as any).blog.main
    },

    '.amaui-logo-support': {
      color: (theme.palette.color as any).support.main,
      fill: (theme.palette.color as any).support.main
    },

    '.amaui-logo-chat': {
      color: (theme.palette.color as any).chat.main,
      fill: (theme.palette.color as any).chat.main
    },

    '.amaui-logo-contact': {
      color: (theme.palette.color as any).contact.main,
      fill: (theme.palette.color as any).contact.main
    },

    '.amaui-logo-location': {
      color: (theme.palette.color as any).location.main,
      fill: (theme.palette.color as any).location.main
    }
  },

  '@media only screen and (max-width: 479px)': {
    '.amaui-Select-wrapper, .amaui-Select-root': {
      width: '100%'
    }
  },

  root: {
    height: '100vh',
    position: 'relative',
    zIndex: 14
  }
}), { name: 'Root' });

const Main = () => {
  const { classes } = useStyle();

  const theme = useAmauiTheme();
  const light = useMediaQuery('(prefers-color-scheme: light)');

  const { close } = useMainLoader();

  const query = useQuery();
  const navigate = useNavigate();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const refs = {
    theme: React.useRef(theme),
    light: React.useRef(light),
    signedIn: React.useRef(signedIn)
  };

  refs.theme.current = theme;

  refs.light.current = light;

  refs.signedIn.current = signedIn;

  const updateTheme = React.useCallback((initial = false) => {
    const valueThemeInitial = {
      options: {},

      preference: {},

      palette: {
        color: {
          primary: {
            light: colors.yellow[300],
            main: colors.yellow[500],
            dark: colors.yellow[700],
          },
          secondary: {
            light: colors.lightgreen[300],
            main: colors.lightgreen[500],
            dark: colors.lightgreen[700],
          },
          tertiary: {
            light: colors.amber[300],
            main: colors.amber[500],
            dark: colors.amber[700],
          },
          quaternary: {
            light: colors.cyan[300],
            main: colors.cyan[500],
            dark: colors.cyan[700],
          }
        }
      },

      typography: {}
    };

    const valueTheme: IAmauiTheme = copy(valueThemeInitial);

    if (refs.signedIn.current?.user) {
      if (getSettings(refs.signedIn.current, 'settings.theme.palette.light') === ('auto' as any)) {
        if (refs.theme.current.palette.light !== refs.light.current) valueTheme.palette!.light = refs.light.current;
      }
      else if (getSettings(refs.signedIn.current, 'settings.theme.palette.light') === ('light' as any)) {
        if (!refs.theme.current.palette.light) valueTheme.palette!.light = true;
      }
      else if (getSettings(refs.signedIn.current, 'settings.theme.palette.light') === ('dark' as any)) {
        if (refs.theme.current.palette.light) valueTheme.palette!.light = false;
      }
    }
    else {
      if (refs.theme.current.palette.light !== refs.light.current) valueTheme.palette!.light = refs.light.current;
    }

    // options
    const optionsMotion = getSettings(refs.signedIn.current, 'settings.theme.options.motion');

    if (optionsMotion !== undefined && refs.theme.current.options.motion !== optionsMotion) {
      valueTheme.options.motion = optionsMotion;
    }

    // preferences
    const visualContrastDefault = getSettings(refs.signedIn.current, 'settings.theme.preference.visual_contrast.default');

    if (visualContrastDefault !== undefined && refs.theme.current.preference.visual_contrast.default !== visualContrastDefault) {
      valueTheme.preference!.visual_contrast = {
        default: visualContrastDefault
      };
    }

    // accessibility
    const accessibility = getSettings(refs.signedIn.current, 'settings.theme.palette.accessibility');

    if (accessibility !== undefined && refs.theme.current.palette.accessibility !== accessibility) {
      valueTheme.palette!.accessibility = accessibility;
    }

    // font size
    const fontSizeHTML = getSettings(refs.signedIn.current, 'settings.theme.typography.font_size.html');

    if (fontSizeHTML !== undefined && refs.theme.current.typography.font_size.html !== fontSizeHTML) {
      valueTheme.typography!.font_size = {
        html: fontSizeHTML
      };
    }

    if (initial) {
      const direction = StorageService.get('direction');

      if (direction !== null) valueTheme.direction = direction ? 'ltr' : 'rtl';
    }

    if (!equalDeep(valueTheme, valueThemeInitial)) refs.theme.current.updateWithRerender(valueTheme);
  }, []);

  const init = React.useCallback(async () => {
    try {
      // update theme
      updateTheme(true);

      // device ID
      let deviceID = StorageService.get('device-id');

      if (!deviceID) {
        deviceID = getID();

        StorageService.add('device-id', deviceID);
      }

      await AuthService.init();

      AmauiService.initial.emit(true);

      // Sockets
      if (signedIn?.user) {
        SocketService.init();
      }

      // invite
      // if valid, sign out, and redirect to proper page
      const invited_id = query.get('invite');

      if (invited_id) {
        const inviteResponse = await UserService.getInvite(invited_id);

        if (inviteResponse.status === 200) {
          const invite = inviteResponse.response.response as User;

          const isNew = (invite as any).new;

          UserService.invite = invite;

          await AuthService.signOut(false);

          navigate({
            pathname: isNew ? '/sign-up' : '/sign-in',
            search: `?invite=${invite.id}`
          });
        }
      }
    }
    catch (error) {
      console.log(error);
    }

    if (config.value.production) {
      Sentry.init({
        dsn: config.value.services.sentry.dsn,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }

    close();
  }, [signedIn, close]);

  React.useEffect(() => {
    // init
    init();

    const methodError = (error: any) => {
      if (error.message.includes('ResizeObserver')) {
        console.error('ResizeObserver', error);

        const webPackOverlay = window.document.getElementById('webpack-dev-server-client-overlay');

        if (webPackOverlay) webPackOverlay.remove();
      }
    };

    window.addEventListener('error', methodError);

    return () => {
      window.removeEventListener('error', methodError);
    };
  }, []);

  // theme
  React.useEffect(() => {
    // update theme
    updateTheme();

    (window as any).amauiTheme = theme;
  }, [signedIn, light, theme]);

  // style
  React.useEffect(() => {
    const id = signedIn?.user?.id;

    if (id) {
      addStyle(`
        body *[data-amaui-id="${id}"] {
          color: inherit;
        }
      `, `amaui-app-mention`);
    }
  }, [signedIn?.user?.id, theme.palette.light]);

  return <>
    <Reset />

    <Meta
      app='amaui'
    />

    <Line
      gap={0}

      direction='column'

      align='unset'

      justify='unset'

      className={classNames([
        'amaui-App',

        classes.root
      ])}
    >
      <Routes />
    </Line>
  </>;
};

export default Main;