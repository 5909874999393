import React from 'react';

import { is, textToInnerHTML } from '@amaui/utils';
import { Line, useForm, useSnackbars } from '@amaui/ui-react';
import { style } from '@amaui/style-react';
import { IMedia } from '@amaui/api-utils';

import { Avatar, Button, Input, Paper, SmartTextField, TextField, PreviewTheme, useLibrary, useSubscription, AutoComplete, Inputs } from 'ui';
import { AuthService, UserService } from 'services';
import { ISignedIn, getErrorMessage, getSettings, languages } from 'other';

const useStyle = style(theme => ({
  root: {

  },

  avatar: {
    width: 114,
    height: 114,
    borderRadius: '50%',
    transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

    '&.amaui-Button-root': {
      cursor: 'pointer',
      userSelect: 'none',

      '& .amaui-Type-root': {
        fontSize: '2.25rem !important'
      }
    },

    '&:active': {
      transform: 'scale(0.94)'
    }
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-route-UserProfile' });

const Element = React.forwardRef(() => {
  const { classes } = useStyle();

  const media = useLibrary();
  const snackbars = useSnackbars();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: getSettings(signedIn, 'name'),
        is: 'string',
        min: 2,
        max: 1400,
        messages: {
          min: 'Name must be min 2 characters',
          max: 'Name can be max 1400 characters'
        },
        required: true
      },
      'properties': {
        name: 'Properties',
        is: 'object',
        value: getSettings(signedIn, 'properties') || {}
      },
      'settings.theme.palette.light': {
        name: 'Theme light',
        value: getSettings(signedIn, 'settings.theme.palette.light') || 'light',
        is: 'string',
        in: ['light', 'dark', 'auto']
      },
      'settings.languages.app': {
        name: 'App language',
        is: 'string',
        value: getSettings(signedIn, 'settings.languages.app') || 'en-US'
      },
      'settings.images.profile': {
        name: 'Profile image',
        value: getSettings(signedIn, 'settings.images.profile'),
        is: 'object'
      }
    },
    valueDefault: {
      name: getSettings(signedIn, 'name'),
      settings: {
        theme: {
          palette: {
            light: getSettings(signedIn, 'settings.theme.palette.light') || 'light'
          }
        },

        images: {
          profile: getSettings(signedIn, 'settings.images.profile')
        }
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const user = signedIn?.user;

  const onUpdate = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading('info');

    const result = await UserService.updatePersonalization(refs.form.current.value);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'Updated'
      });

      // refresh user
      // in the entire app
      await AuthService.me();
    }

    setLoading(false);
  }, [form]);

  const onImageProfileConfirm = React.useCallback((value: IMedia[]) => {
    const image = Array.isArray(value) ? value[0] : value;

    if (image) {
      form.onChange('settings.images.profile', image);
    }
  }, [form]);

  const onOpenMediaImageProfile = React.useCallback(() => {
    media.open({
      search: {
        mime: ['image']
      },
      selected: [form.values['settings.images.profile']?.value].filter(Boolean),
      onConfirm: onImageProfileConfirm
    });
  }, [media, onImageProfileConfirm]);

  const optionsLanguage = React.useMemo(() => {
    const results: any = [];

    languages.speech.forEach(([category, ...options]) => {
      (options as any).forEach((option: any) => {
        const value_ = is('string', option) ? option : option[0];
        const name_ = is('string', option) ? '' : option[1];

        results.push({ name: `${category}${name_ ? `, ${name_}` : ''} ${value_}`, value: value_ });
      });
    });

    return results;
  }, [languages]);

  let initials = '';

  if (signedIn) {
    const [firstName, lastName] = getSettings(signedIn, 'name')!.split(' ');

    initials += firstName.charAt(0);

    initials += ((lastName ? lastName.charAt(0) : firstName.charAt(1)) || '');
  }

  initials = initials?.toLowerCase();

  return (
    <Paper
      name='Profile'

      midFooter={(
        <Button
          onClick={onUpdate}

          size='large'

          disabled={loading}
        >
          Update
        </Button>
      )}
    >
      <Line
        fullWidth
      >
        <Line
          gap={1.5}

          align='center'

          fullWidth

          style={{
            marginBottom: 24
          }}
        >
          <Avatar
            size='large'

            media={form.values['settings.images.profile']?.value}

            onClick={onOpenMediaImageProfile}

            className={classes.avatar}
          >
            {initials}
          </Avatar>

          <SmartTextField
            name='Name'

            valueDefault={textToInnerHTML(getSettings(signedIn, 'name') || '')}

            error={!!form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: string) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            additional={{
              version: 'h2',
              align: 'center',
              whiteSpace: 'pre-line',
              style: {
                width: 'auto'
              }
            }}
          />
        </Line>

        <Inputs>
          <Input
            name='Email'
          >
            <TextField
              valueDefault={user?.email}

              fullWidth

              disabled
            />
          </Input>

          <Input
            name='Theme'

            description='Choose whether app theme is light, dark or auto which follows your operating system theme.'
          >
            <Line
              gap={1}

              direction={{
                default: 'row',
                xxs: 'column',
                xs: 'column'
              }}

              align='flex-start'

              justify='flex-start'
            >
              <PreviewTheme
                version='light-logo-preview'

                onClick={() => form.onChange('settings.theme.palette.light', 'light')}

                selected={form.values['settings.theme.palette.light'].value === 'light'}
              />

              <PreviewTheme
                version='dark-logo-preview'

                onClick={() => form.onChange('settings.theme.palette.light', 'dark')}

                selected={form.values['settings.theme.palette.light'].value === 'dark'}
              />

              <PreviewTheme
                version='auto-logo-preview'

                onClick={() => form.onChange('settings.theme.palette.light', 'auto')}

                selected={form.values['settings.theme.palette.light'].value === 'auto'}
              />
            </Line>
          </Input>

          <Input
            name='Language'

            description='Used for text to speech, speech to text translations etc.'
          >
            <AutoComplete
              name='Language'

              valueInputDefault={optionsLanguage.find((item: any) => item.value === form.values['settings.languages.app'].value)?.name}

              value={form.values['settings.languages.app'].value || ''}

              onChange={(valueNew: any) => form.onChange('settings.languages.app', valueNew?.value)}

              options={optionsLanguage}

              fullWidth
            />
          </Input>
        </Inputs>
      </Line>
    </Paper>
  );
});

export default Element;
