import React from 'react';

import { IconButton, Menu, MenuItem, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import IconMaterialMoreVertW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialMoreVertW100';

const useStyle = style(theme => ({
  root: {
    '&.amaui-Modal-root': {
      minWidth: 150
    }
  }
}), { name: 'amaui-app-ItemMenu' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    items,

    element,

    IconButtonProps,

    IconProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const onContextMenuPrevent = React.useCallback((event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const menuItems = React.useMemo(() => {

    return items?.map((item: any) => {
      const {
        props: otherProps,

        ...otherMenuItem
      } = item;

      return (
        <MenuItem
          menuCloseOnClick

          {...otherMenuItem}

          primary={(
            <Type
              version='l1'

              {...otherProps}
            >
              {item.name}
            </Type>
          )}

          button
        />
      );
    });
  }, [items]);

  return (
    <Menu
      alignment='end'

      menuItems={menuItems}

      onContextMenu={onContextMenuPrevent}

      {...other}

      include={[element].filter(Boolean)}

      includeParentQueries={['.amaui-ObjectCalendar-root', '.amaui-repeat', '.amaui-repeat-end', '.amaui-time', '.amaui-reminder']}

      portal

      ignoreNonExisting

      ListProps={{
        color: 'themed',
        size: 'small',

        ...other?.ListProps
      }}

      className={classNames([
        '.amaui-MoreMenu-root',
        classes.root
      ])}
    >
      <IconButton
        color='inherit'

        size='regular'

        onContextMenu={onContextMenuPrevent}

        disabled={!menuItems?.length}

        {...IconButtonProps}
      >
        <IconMaterialMoreVertW100Rounded
          size='large'

          {...IconProps}
        />
      </IconButton>
    </Menu>
  );
});

export default Element;
