import React from 'react';
import { useNavigate } from 'react-router-dom';

import { is } from '@amaui/utils';
import { Expand, Line, Tab, Tabs, Type, useLocation } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import IconMaterialViewColumnRounded from '@amaui/icons-material-rounded-react/IconMaterialViewColumnW100';
import IconMaterialArticleRounded from '@amaui/icons-material-rounded-react/IconMaterialArticleW100';
import IconMaterialHelpCenterRounded from '@amaui/icons-material-rounded-react/IconMaterialHelpCenterW100';
import IconMaterialCalendarMonthRounded from '@amaui/icons-material-rounded-react/IconMaterialCalendarMonthW100';
import IconMaterialNoteRounded from '@amaui/icons-material-rounded-react/IconMaterialNoteW100';
import IconMaterialPersonRounded from '@amaui/icons-material-rounded-react/IconMaterialPersonW100';
import IconMaterialWbSunnyRounded from '@amaui/icons-material-rounded-react/IconMaterialWbSunnyW100';
import IconMaterialWbTwilightRounded from '@amaui/icons-material-rounded-react/IconMaterialWbTwilightW100';
import IconMaterialCounter7Rounded from '@amaui/icons-material-rounded-react/IconMaterialCounter7W100';
import IconMaterialTaskAltRounded from '@amaui/icons-material-rounded-react/IconMaterialTaskAltW100';
import IconMaterialNotificationsW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialNotificationsW100';
import IconMaterialLockW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialLockW100';
import IconMaterialFileUploadW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialFileUploadW100';
import IconMaterialImagesmodeW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialImagesmodeW100';
import IconMaterialAccountCircleW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialAccountCircleW100';
import IconMaterialIntegrationInstructionsW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialIntegrationInstructionsW100';
import IconMaterialAccountBalanceWalletW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialAccountBalanceWalletW100';
import IconMaterialPersonFilledW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialPersonFilledW100';
import IconMaterialStoreW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialStoreW100';

import { ReactComponent as Logo } from 'assets/svg/logo-light.svg';

import { AppsMenu, NotificationsMenu, UserMenu, useSubscription } from 'ui';
import { AuthService } from 'services';
import { ISignedIn, getRootPage, isHex } from 'other';

const useStyle = style(theme => ({
  root: {
    flex: '0 0 auto',
    zIndex: 14
  },

  header: {
    position: 'relative',
    height: 70,
    padding: '0px 16px',
    flex: '0 0 auto'
  },

  logo: {
    '& > svg': {
      width: 40,
      height: 'auto',
      fill: theme.palette.light ? '' : theme.palette.color.amaui[20],
      cursor: 'pointer',
      userSelect: 'none',
      transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

      '&:active': {
        transform: 'scale(0.94)'
      }
    }
  },

  tabs: {
    '& .amaui-Tabs-line': {
      height: 2
    }
  },

  tab: {
    '&.amaui-Tab-root': {
      padding: '8px 20px'
    }
  }
}), { name: 'amaui-app-Header' });

const Header = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const navigate = useNavigate();
  const location = useLocation();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [page, setPage] = React.useState<any>();
  const [open, setOpen] = React.useState(false);

  const refs = {
    root: React.useRef<any>()
  };

  const user = signedIn?.user;

  const isUser = user?.is?.user;

  const routes = React.useMemo(() => {

    return {
      tasks: isUser ? [
        { name: 'Today', to: '/tasks', Icon: IconMaterialWbSunnyRounded },
        { name: 'Tomorrow', to: '/tasks/tomorrow', Icon: IconMaterialWbTwilightRounded },
        { name: 'Week', to: '/tasks/week', Icon: IconMaterialCounter7Rounded },
        { name: 'All', to: '/tasks/all', Icon: IconMaterialTaskAltRounded },
        { name: 'Assigned to me', to: '/tasks/assigned-to-me', Icon: IconMaterialPersonRounded },
        { name: 'Month', to: '/tasks/month', Icon: IconMaterialCalendarMonthRounded },
        { name: 'Lists', to: '/tasks/lists', Icon: IconMaterialViewColumnRounded }
      ] : [],

      notes: isUser ? [
        { name: 'My notes', to: '/notes', Icon: IconMaterialNoteRounded },
        { name: 'Mentions', to: '/notes/mentions', Icon: IconMaterialPersonRounded }
      ] : [],

      support: isUser ? [
        { name: 'Support pages', to: '/support-pages', Icon: IconMaterialHelpCenterRounded },
        { name: 'Support articles', to: '/support-articles', Icon: IconMaterialArticleRounded }
      ] : [],

      library: [
        { name: 'Library', to: '/library', Icon: IconMaterialImagesmodeW100Rounded },
        { name: 'Upload', to: '/library/upload', Icon: IconMaterialFileUploadW100Rounded }
      ],

      settings: [
        { name: 'Profile', to: '/settings', Icon: IconMaterialAccountCircleW100Rounded },
        { name: 'Security', to: '/settings/security', Icon: IconMaterialLockW100Rounded },
        { name: 'Notifications', to: '/settings/notifications', Icon: IconMaterialNotificationsW100Rounded }
      ],

      organization_settings: [
        { name: 'Organization', to: '/organization/settings/info', Icon: IconMaterialStoreW100Rounded },
        { name: 'Employees', to: '/organization/settings/employees', Icon: IconMaterialPersonFilledW100Rounded },
        { name: 'Subscription', to: '/organization/settings/subscription', Icon: IconMaterialAccountBalanceWalletW100Rounded },
        { name: 'Integrations', to: '/organization/settings/integrations', Icon: IconMaterialIntegrationInstructionsW100Rounded }
      ]
    };
  }, [signedIn]);

  React.useEffect(() => {
    const pathname = location.pathname;

    let page_ = pathname.split('/').filter(Boolean)[0];

    if (['templates'].includes(page_)) page_ = 'websites';

    if (['support-pages', 'support-articles'].includes(page_)) page_ = 'support';

    if (['contact-groups'].includes(page_)) page_ = 'contacts';

    if (pathname.startsWith('/organization/settings')) page_ = 'organization_settings';

    setPage(page_);
  }, [location]);

  const isActive = React.useCallback((pathname: string, route: string) => {
    const end = pathname.replace(`${route}/`, '');

    return !!(
      pathname === route ||
      (pathname.startsWith(route) && (end === 'add' || isHex(`0x${end}`)))
    );
  }, []);

  const menus = (routes as any)[page] as { name: string; to: string; }[];

  React.useEffect(() => {
    setOpen(!!menus?.length);
  }, [menus]);

  const iconProps = {
    size: 'large'
  };

  return (
    <Line
      ref={(item: any) => {
        refs.root.current = item;

        if (ref) {
          if (is('function', ref)) ref(item)
          else ref.current = item;
        }
      }}

      gap={0}

      direction='column'

      justify='unset'

      align='unset'

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      {/* Header */}
      <Line
        gap={2}

        direction='row'

        justify='space-between'

        align='center'

        fullWidth

        className={classNames([
          classes.header
        ])}
      >
        <Line
          gap={2}

          direction='row'

          align='center'
        >
          <Line
            className={classes.logo}
          >
            <Logo
              onClick={() => navigate(getRootPage(signedIn))}
            />
          </Line>
        </Line>

        <Line
          gap={1.5}

          direction='row'

          align='center'
        >
          <AppsMenu />

          <NotificationsMenu />

          <UserMenu />
        </Line>
      </Line>

      {/* Page menus */}
      <Expand
        in={open}
      >
        <Line
          gap={0}

          direction='row'

          justify='unset'

          align='center'

          fullWidth
        >
          <Tabs
            value={location.pathname}

            isActive={isActive}

            size='large'

            noDivider

            className={classes.tabs}
          >
            {menus?.map((item: any) => (
              <Tab
                key={item.to}

                onClick={() => navigate(item.to)}

                value={item.to}

                icon={item.Icon ? <item.Icon {...iconProps} /> : null}

                className={classes.tab}
              >
                <Type
                  version='t2'
                >
                  {item.name}
                </Type>
              </Tab>
            ))}
          </Tabs>
        </Line>
      </Expand>
    </Line>
  );
});

export default Header;
