import React from 'react';
import audioFix from 'webm-duration-fix';

import { innerHTMLToText, is, textToInnerHTML } from '@amaui/utils';
import { IconButton, Line, ListItem, Medias, Menu, MenuItem, Type, useForm, useSnackbars, useSubscription } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { AmauiDate, add, format, is as Is, remove, startOf } from '@amaui/date';
import { Task } from '@amaui/api-utils';

import IconMaterialFlagFilledRounded from '@amaui/icons-material-rounded-react/IconMaterialFlagW100Filled';
import IconMaterialCalendarTodayRounded from '@amaui/icons-material-rounded-react/IconMaterialCalendarTodayW100';

import { Button, SmartTextField, ObjectCalendar, SpeechToText, TextToSpeech, AudioRecorder } from 'ui';
import { AuthService, MediaService, TaskService } from 'services';
import { ISignedIn, formats, getElementText, getErrorMessage, mediasToValue } from 'other';

const useStyle = style(theme => ({
  root: {
    '& .amaui-Button-root': {
      flex: '0 0 auto'
    },

    '& .amaui-TextField-input-wrapper': {
      padding: '0 !important',
      height: 'auto !important'
    },

    '& .amaui-Type-root': {
      whiteSpace: 'normal'
    }
  },

  main: {
    height: 0,
    maxHeight: '54vh',
    overflowY: 'auto',

    '& .amaui-TextField-border': {
      boxShadow: 'none'
    }
  },

  audio: {
    maxWidth: '100%',
    maxHeight: 40
  },

  endDate: {
    '&.amaui-ListItem-root': {
      padding: '2px 4px'
    }
  },

  endDateWrapper: {
    '&.amaui-ListItem-wrapper': {
      width: 'auto'
    }
  },

  footer: {
    overflowX: 'auto'
  }
}), { name: 'amaui-app-TaskAdd' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    onConfirm,

    onClose: onClose_,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();
  const snackbars = useSnackbars();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [loading, setLoading] = React.useState<any>(false);

  const refs = {
    name: React.useRef<HTMLElement>(),
    value: React.useRef<HTMLElement>(),
    form: React.useRef<any>(),
    original: React.useRef<any>()
  };

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: '',
        max: 1400,
        required: true,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'value': {
        name: 'Description',
        value: '',
        max: 14000,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 14000 characters'
        }
      },
      'ends_at': {
        name: 'Due date',
        is: 'number',
        value: AmauiDate.milliseconds
      },
      'priority.name': {
        name: 'Priority',
        value: 'default',
        is: 'string',
        in: ['default', 'low', 'medium', 'important']
      },
      'priority.value': {
        name: 'Priority value',
        value: 1,
        is: 'number',
        min: 1,
        max: 1000
      },
      'repeat': {
        name: 'Repeat',
        is: 'object',
        value: {}
      },
      'reminders': {
        name: 'Reminders',
        is: 'array',
        of: 'object'
      },
      'media': {
        name: 'Media',
        value: [],
        is: 'array',
        of: 'object'
      }
    },

    autoValidate: true
  });

  refs.form.current = form;

  const onClose = React.useCallback((event?: MouseEvent) => {
    if (is('function', onClose_)) onClose_(event);

    setTimeout(() => {
      refs.form.current.clear();
    }, 1400);
  }, [onClose_]);

  const isValid = form.value.name || form.value.value;

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid || !isValid) return;

    setLoading(true);

    const value = refs.form.current.value;

    const body: Partial<Task> = {
      name: value.name,
      done: value.done,
      ends_at: value.ends_at,
      repeat: value.repeat,
      priority: value.priority,
      media: value.media
    };

    if (value.value !== undefined) {
      body.value = [
        {
          version: 'type',
          value: innerHTMLToText(value.value)
        }
      ];
    }

    const result = await TaskService.add(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Task added`
      });

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [isValid, form, onConfirm, onClose]);

  let valueDate: string = 'Due date';

  const dates: any = {};

  if (form.value.ends_at) {
    const now = new AmauiDate();
    const ends = new AmauiDate(form.value.ends_at);

    valueDate = format(ends, 'DD MMM');

    const starts = {
      now: startOf(now, 'day'),
      ends: startOf(ends, 'day')
    };

    dates.inThePast = Is(starts.ends, 'before', starts.now);

    dates.yesterday = format(remove(1, 'day', now), 'DD MMM YYYY') === format(ends, 'DD MMM YYYY');

    dates.today = format(now, 'DD MMM YYYY') === format(ends, 'DD MMM YYYY');

    dates.tomorrow = format(add(1, 'day', now), 'DD MMM YYYY') === format(ends, 'DD MMM YYYY');

    if (dates.yesterday) valueDate = `Yesterday, ${valueDate}`;

    if (dates.today) valueDate = `Today, ${valueDate}`;

    if (dates.tomorrow) valueDate = `Tomorrow, ${valueDate}`;
  }

  const color = !form.value.ends_at ? 'default' : !dates.inThePast ? 'success' : 'error';

  const priorityToColor = (value: any) => {
    if (value === 'low') return 'info';

    if (value === 'medium') return 'warning';

    if (value === 'important') return 'error';

    return 'primary';
  };

  const getMenuItemsPriority = () => {
    const values = [
      { name: 'Default', onClick: () => { form.onChange([['priority.name', 'default'], ['priority.value', 1]]); } },
      { name: 'Low', onClick: () => { form.onChange([['priority.name', 'low'], ['priority.value', 2]]); } },
      { name: 'Medium', onClick: () => { form.onChange([['priority.name', 'medium'], ['priority.value', 3]]); } },
      { name: 'Important', onClick: () => { form.onChange([['priority.name', 'important'], ['priority.value', 4]]); } }
    ];

    return values.map((item: any, index: number) => (
      <MenuItem
        key={index}

        start={(
          <IconMaterialFlagFilledRounded
            color={priorityToColor(item.name?.toLowerCase())}
          />
        )}

        primary={(
          <Type
            version='b3'
          >
            {item.name}
          </Type>
        )}

        size='small'

        onClick={item.onClick}

        menuCloseOnClick

        button
      />
    ));
  };

  const onAudioRecorderConfirm = React.useCallback(async (value: Blob) => {
    setLoading('voice recording');

    const media = await audioFix(value);

    // validate
    // 140 mb maximum
    if (media.size > (140 * 1e6)) {
      snackbars.add({
        color: 'error',
        primary: `Maximum allowed file size is 140 mb`
      });

      return;
    }

    const name = `Voice recording, ${format(new AmauiDate(), formats.entire)}`;

    // meta
    const meta: any = {};

    const result = await MediaService.add({
      name,

      meta,

      app: 'task',

      // media
      media
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const mediaMongo = result?.response?.response;

      // Update the note
      const medias = refs.form.current.values.media.value || [];

      medias.push(mediasToValue({
        id: mediaMongo?.id,
        name,
        mime: media.type,
        meta: mediaMongo?.meta,
        versions: mediaMongo?.versions,
        added_at: AmauiDate.milliseconds
      }));

      refs.form.current.onChange('media', medias);
    }

    setLoading(false);
  }, [onConfirm]);

  const onSpeechToTextStart = React.useCallback(() => {
    refs.original.current = textToInnerHTML(refs.form.current.values.value.value);
  }, []);

  const onSpeechToTextStop = React.useCallback(() => {
    refs.original.current = '';
  }, []);

  const onSpeechToTextUpdate = React.useCallback((valueNew: any) => {
    refs.value.current!.innerHTML = refs.original.current;

    const div = window.document.createElement('div');

    div.innerHTML = valueNew;

    refs.value.current!.append(div);

    refs.form.current.onChange('value', innerHTMLToText(refs.value.current!.innerHTML));
  }, []);

  const propsOther: any = {
    size: 'small'
  };

  return (
    <Line
      gap={2}

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Line
        gap={4}

        flex

        fullWidth

        className={classes.main}
      >
        <Line
          gap={1.5}

          justify='unset'

          align='unset'

          flex

          fullWidth
        >
          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth
          >
            <ObjectCalendar
              value={form.value}

              onChange={form.onChange}

              closeOnChange={false}

              RepeatProps={{
                fromOptions: ['end', 'done']
              }}
            >
              <ListItem
                primary={(
                  <Type
                    version='l3'

                    color={color}
                  >
                    {valueDate}
                  </Type>
                )}

                start={(
                  <IconMaterialCalendarTodayRounded
                    color={color}
                  />
                )}

                startAlign='center'

                size='small'

                button

                noBackground

                Component='div'

                WrapperProps={{
                  className: classes.endDateWrapper
                }}

                RootProps={{
                  className: classes.endDate
                }}
              />
            </ObjectCalendar>

            <Menu
              menuItems={getMenuItemsPriority()}
            >
              <IconButton
                size='small'
              >
                <IconMaterialFlagFilledRounded
                  color={priorityToColor(form.values['priority.name'].value)}

                  size='regular'
                />
              </IconButton>
            </Menu>
          </Line>

          <Line
            gap={1}

            fullWidth
          >
            <SmartTextField
              ref={refs.name}

              placeholder='Name'

              onChange={(valueNew: string) => form.onChange('name', valueNew)}

              additional={{
                version: 't2'
              }}

              style={{
                whiteSpace: 'normal'
              }}
            />

            <SmartTextField
              ref={refs.value}

              placeholder='Description'

              onChange={(valueNew: string) => form.onChange('value', valueNew)}

              additional={{
                version: 'b2'
              }}

              edit

              multiline

              mention
            />
          </Line>
        </Line>

        <Medias
          values={form.values.media.value?.map((item: any) => ({
            value: item
          }))}
        />
      </Line>

      <Line
        direction='row'

        justify='space-between'

        fullWidth

        className={classes.footer}
      >
        <Button
          version='text'

          color='inherit'

          onClick={onClose}

          disabled={loading === true}

          {...propsOther}
        >
          Close
        </Button>

        <Line
          gap={1}

          direction='row'

          align='center'
        >
          <SpeechToText
            onData={onSpeechToTextUpdate}

            onChange={onSpeechToTextUpdate}

            onStart={onSpeechToTextStart}

            onStop={onSpeechToTextStop}

            language={signedIn?.organization?.personalization?.settings?.languages?.speech}

            disabled={loading}

            IconProps={{
              size: 'regular'
            }}

            {...propsOther}
          />

          {!!form.value.value?.length && (
            <TextToSpeech
              read={getElementText(textToInnerHTML(form.value.value))}

              language={signedIn?.organization?.personalization?.settings?.languages?.speech}

              IconProps={{
                size: 'regular'
              }}

              {...propsOther}
            />
          )}

          <AudioRecorder
            onConfirm={(value: Blob) => onAudioRecorderConfirm(value)}

            disabled={loading}

            IconProps={{
              size: 'regular'
            }}

            {...propsOther}
          />

          <Button
            color={theme.palette.light ? 'default' : 'inverted'}

            elevation={false}

            onClick={onNext}

            {...propsOther}
          >
            Next
          </Button>
        </Line>
      </Line>
    </Line>
  );
});

export default Element;
