import React from 'react';

import { capitalize, copy, getID } from '@amaui/utils';
import { Form, IconButton, Line, Tooltip } from '@amaui/ui-react';
import { AmauiDate } from '@amaui/date';

import IconMaterialRemove from '@amaui/icons-material-rounded-react/IconMaterialRemoveW100';
import IconMaterialAdd from '@amaui/icons-material-rounded-react/IconMaterialAddW100';

import { DatePicker, Inputs, TextField } from 'ui';

const toName = (value_: any, toCapitalize = true) => {
  let valueNew = value_;

  if (value_ === 'urls') valueNew = 'links';

  if (value_ === 'custom') valueNew = 'more';

  return toCapitalize ? capitalize(valueNew) : valueNew;
};

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    form,

    versions = ['tels', 'emails', 'titles', 'urls', 'addresses', 'companies', 'birthdays', 'dates', 'custom'],

    ...other
  } = props;

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const keyToVersion = React.useCallback((item: any) => {
    if (['tels'].includes(item)) return 'mobile';

    if (['emails'].includes(item)) return 'email';

    if (['titles'].includes(item)) return 'title';

    if (['urls'].includes(item)) return 'url';

    if (['addresses'].includes(item)) return 'address';

    if (['companies'].includes(item)) return 'company';

    if (['birthdays'].includes(item)) return 'birthday';

    if (['dates'].includes(item)) return 'date';

    if (['custom'].includes(item)) return 'custom';

    return 'custom';
  }, []);

  const onAddProperty = React.useCallback((key: string, version: string) => {
    const properties = refs.form.current.values.properties.value || {};

    if (!properties[key]) properties[key] = [];

    properties[key].push({
      id: getID(),
      version,
      type: 'regular',
      value: ''
    });

    refs.form.current.onChange('properties', properties);
  }, []);

  const onUpdateProperty = React.useCallback((key: string, item: any, prop: string, valueNew_: any) => {
    let valueNew = valueNew_;

    if (['birthday', 'date'].includes(item.version)) valueNew = valueNew_.milliseconds;

    const properties = copy(refs.form.current.values.properties.value || {});

    if (!properties[key]) properties[key] = [];

    const index = properties[key].findIndex((item_: any) => item_.id === item.id);

    if (index > -1) {
      refs.form.current.onChange('properties', valueNew, `${key}.${index}.${prop}`, { rerenderOnUpdate: false });
    }
  }, []);

  const onRemoveProperty = React.useCallback((key: string, item: any) => {
    const properties = copy(refs.form.current.values.properties.value || {});

    if (!properties[key]) properties[key] = [];

    const index = properties[key].findIndex((item_: any) => item_.id === item.id);

    if (index > -1) {
      properties[key].splice(index, 1);

      refs.form.current.onChange('properties', properties);
    }
  }, []);

  const iconProps: any = {
    size: 'large'
  };

  const getInput = React.useCallback((key: string, itemProperty: any) => {
    const item = refs.form.current.values.properties.value[key]?.find((item_: any) => item_.id === itemProperty?.id);

    if (!item) return null;

    const getName = (item: any) => {
      if (item === 'url') return 'URL';

      return toName(item);
    };

    const otherProps = {
      name: getName(item.version),

      valueDefault: ['birthday', 'date'].includes(item.version) ? item.value ? new AmauiDate(item.value) : null : item.value || '',

      onChange: (valueNew: any) => onUpdateProperty(key, itemProperty, 'value', valueNew),

      size: 'small',

      fullWidth: true
    };

    let Input = <TextField />;

    if (item.version === 'email') Input = <TextField type='email' />;

    if (['mobile', 'tel', 'fax'].includes(item.version)) Input = <TextField type='tel' />;

    if (item.version === 'address') Input = <TextField type='address' />;

    if (item.version === 'url') Input = <TextField type='url' />;

    if (['birthday', 'date'].includes(item.version)) Input = <DatePicker />;

    return (
      <Line
        key={item.id}

        direction='row'

        align='center'

        fullWidth
      >
        {React.cloneElement(Input, {
          ...otherProps
        })}

        <Tooltip
          name='Remove'
        >
          <IconButton
            onClick={() => onRemoveProperty(key, item)}
          >
            <IconMaterialRemove
              {...iconProps}
            />
          </IconButton>
        </Tooltip>
      </Line>
    );
  }, [onRemoveProperty]);

  const properties_ = form.values.properties.value || {};

  return (
    <Inputs
      name='Details'

      {...other}
    >
      {versions.map((version: string) => (
        <Form
          key={version}

          name={toName(version)}

          gap={0.5}

          wrapper

          end={(
            <Tooltip
              name={`Add ${toName(version, false)}`}
            >
              <IconButton
                onClick={() => onAddProperty(version, keyToVersion(version))}
              >
                <IconMaterialAdd
                  {...iconProps}
                />
              </IconButton>
            </Tooltip>
          )}
        >
          <Line
            gap={1.5}

            fullWidth
          >
            {properties_[version]?.map((item: any) => getInput(version, item))}
          </Line>
        </Form>
      ))}
    </Inputs>
  );
});

export default Element;
