import React from 'react';

import { Checkbox, useConfirm, useSnackbars, useSubscription } from '@amaui/ui-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { IMedia } from '@amaui/api-utils';

import { getErrorMessage, getMediaThumbnailURL, getMediaUrl, ISelectedSubscription } from 'other';
import { MediaService } from 'services';
import { ItemCardBox, ItemMenu } from 'ui';

export interface IItemMedia extends IBaseElement {
  object: IMedia;

  open?: boolean;

  select?: boolean;

  multiple?: boolean;

  remove?: boolean;

  selectObjectsName?: string;
}

const Element: React.FC<IItemMedia> = React.forwardRef((props, ref: any) => {
  const {
    object = {},

    open = true,

    select = false,

    multiple = true,

    remove = true,

    selectObjectsName = 'selectObjects',

    className,

    ...other
  } = props;

  const confirm = useConfirm();
  const snackbars = useSnackbars();

  const {
    id,

    mime,

    name
  } = object;

  const selectedObjects = useSubscription<ISelectedSubscription>((MediaService as any)[selectObjectsName]);

  const isImage = mime?.startsWith('image/');

  const isVideo = mime?.startsWith('video/');

  const url = getMediaUrl(object);

  const urlImage = isImage ? getMediaUrl({ id }, 480) : isVideo ? getMediaThumbnailURL(object) : '/assets/images/not-found-1.png';

  const onOpen = React.useCallback(() => window.open(url), [url]);

  const onRemove = React.useCallback(async () => {
    if (!(await confirm.open({ name: `Removing ${name} media` }))) return;

    const result = await MediaService.remove(id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Media removed`
      });
    }

    // refresh 
    MediaService.refetch();
  }, [object]);

  if (!object) return null;

  const isSelected = selectedObjects.isSelected(object);

  return (
    <ItemCardBox
      ref={ref}

      name={name}

      meta={mime}

      image={urlImage}

      onOpen={onOpen}

      selected={isSelected}

      startFooter={select && (
        <Checkbox
          checked={isSelected}

          onChange={(valueNew: any) => {
            if (!multiple) selectedObjects.unselectAll();

            valueNew ? selectedObjects.select(object) : selectedObjects.unselect(object);
          }}
        />
      )}

      endFooter={(open || remove) && (
        <ItemMenu
          items={[
            ...(open ? [{ name: 'Open', onClick: onOpen }] : []),
            ...(remove ? [{ name: 'Remove', onClick: onRemove, props: { color: 'error' } }] : [])
          ]}
        />
      )}

      {...other}
    />
  );
});

export default Element;
