import React from 'react';

import { is } from '@amaui/utils';
import { Select } from '@amaui/ui-react';
import { ISelect } from '@amaui/ui-react/Select/Select';
import { classNames, style } from '@amaui/style-react';

import IconMaterialCloseW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialCloseW100';
import IconMaterialArrowDropDownW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialArrowDropDownW100';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-Select' });

const Element: React.FC<ISelect> = React.forwardRef((props, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  const refs = {
    root: React.useRef<HTMLElement>()
  };

  return (
    <Select
      ref={(item: any) => {
        if (ref) {
          if (is('function', ref)) ref(item);
          ref.current = item;
        }

        refs.root.current = item;
      }}

      version='outlined'

      size='small'

      IconClear={IconMaterialCloseW100Rounded}

      IconDropdown={IconMaterialArrowDropDownW100Rounded}

      IconButtonProps={{
        size: 'regular'
      }}

      IconProps={{
        size: 'large'
      }}

      MenuProps={{
        portal: true,

        size: 'regular',

        style: {
          width: refs.root.current?.clientWidth
        }
      }}

      className={classNames([
        className,
        classes.root
      ])}

      WrapperProps={{
        className: classNames([
          classes.wrapper
        ])
      }}

      clear

      {...other}
    />
  );
});

export default Element;
