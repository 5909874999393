import React from 'react';

import { hash, is } from '@amaui/utils';
import { IconButton, Line, Tooltip, Type, useLocation, useSnackbars, useSubscription } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { IResponse } from '@amaui/sdk/other';

import IconMaterialAddW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialAddW100';
import IconMaterialSelectW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialSelectW100';

import { getErrorMessage, IQuerySubscription, ISelectedSubscription, isHex } from 'other';
import { Loading, Search } from '.';
import { ISearchItem } from './Search';
import { AppService } from 'services';

const useStyles = style(theme => ({
  root: {

  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Page' });

export interface IPage {
  name?: any;

  singular?: string;

  plural?: string;

  startName?: any;

  endName?: any;

  service?: any;

  queryObjectsName?: string;

  selectObjectsName?: string;

  getMethodName?: string;

  queryDefault?: any;

  search?: ISearchItem[];

  parent?: any;

  appPriority?: string;

  add?: boolean;

  addProps?: any;

  updateProps?: any;

  FormAdd?: any;

  FormUpdate?: any;

  formAddProps?: any;

  formUpdateProps?: any;

  onConfirmProps?: any;

  more?: any;

  start?: any;

  end?: any;

  open?: boolean;

  secondary?: boolean;

  stripe?: boolean;

  onQueryAfter?: (result: IResponse) => any;

  onAdd?: (event: MouseEvent) => any;

  children?: (value: IQuerySubscription) => any;

  className?: string;

  ref?: any;
}

const Element: React.FC<IPage> = React.forwardRef((props, ref) => {
  const {
    name,

    singular: singularProps,

    plural: pluralProps,

    startName,

    endName,

    service,

    queryObjectsName = 'queryObjects',

    selectObjectsName = 'selectObjects',

    getMethodName = 'get',

    queryDefault: queryDefaultProps,

    search,

    parent,

    onQueryAfter,

    appPriority = 'add',

    addProps = {
      version: 'mid'
    },

    updateProps = {
      version: 'mid'
    },

    add = true,

    more,

    start,

    end,

    open = true,

    secondary,

    stripe,

    FormAdd,

    FormUpdate,

    formAddProps,

    formUpdateProps,

    onConfirmProps = [],

    onAdd,

    children,

    className,

    ...other
  } = props;

  const { classes } = useStyles();

  const snackbars = useSnackbars();
  const location = useLocation();

  // objects 
  const queryObjects = useSubscription<IQuerySubscription>(service[queryObjectsName]);
  const selectObjects = useSubscription<ISelectedSubscription>(service[selectObjectsName]);

  const [loaded, setLoaded] = React.useState(false);

  const queryDefault = React.useMemo(() => {
    return queryDefaultProps || {};
  }, [queryDefaultProps]);

  const refs = {
    service: React.useRef(service),
    parent: React.useRef(parent),
    queryObjects: React.useRef(queryObjects),
    queryObjectsName: React.useRef(queryObjectsName),
    selectObjectsName: React.useRef(selectObjectsName),
    getMethodName: React.useRef(getMethodName),
    queryDefault: React.useRef(queryDefault),
    appPriority: React.useRef(appPriority),
    addProps: React.useRef(addProps),
    updateProps: React.useRef(updateProps),
    formAddProps: React.useRef(formAddProps),
    formUpdateProps: React.useRef(formUpdateProps),
    onConfirmProps: React.useRef(onConfirmProps),
    onQueryAfter: React.useRef(onQueryAfter),
    open: React.useRef(open)
  };

  refs.service.current = service;

  refs.parent.current = parent;

  refs.queryObjects.current = queryObjects;

  refs.queryObjectsName.current = queryObjectsName;

  refs.selectObjectsName.current = selectObjectsName;

  refs.getMethodName.current = getMethodName;

  refs.queryDefault.current = queryDefault;

  refs.appPriority.current = appPriority;

  refs.addProps.current = addProps;

  refs.updateProps.current = updateProps;

  refs.formAddProps.current = formAddProps;

  refs.formUpdateProps.current = formUpdateProps;

  refs.onConfirmProps.current = onConfirmProps;

  refs.onQueryAfter.current = onQueryAfter;

  refs.open.current = open;

  const singular = ['URL shortener'].includes(singularProps!) ? singularProps : (singularProps || name).toLowerCase();

  const plural = ['URL shorteners'].includes(pluralProps!) ? pluralProps : (pluralProps || name).toLowerCase();

  const queryDefaultHashed = React.useMemo(() => {
    return hash(queryDefault);
  }, [queryDefault]);

  const onClose = React.useCallback(() => {
    AppService.pages[refs.appPriority.current as 'add'].emit({
      ...AppService.pages[refs.appPriority.current as 'add'].value,

      open: false
    });
  }, []);

  const onOpenDefault = React.useCallback(async (id: string, response: any) => {
    if (!refs.open.current) return;

    let object = response?.find((item: any) => item.id === id);

    // get 
    if (!object) {
      const result = await refs.service.current[refs.getMethodName.current](id);

      if (result.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });

        return;
      }
      else {
        object = result.response.response;
      }
    }

    if (!(FormUpdate && object)) return;

    AppService.pages[refs.appPriority.current as 'add'].emit({
      open: true,

      to: object.id,

      singular,

      plural,

      secondary,

      ...refs.updateProps.current,

      children: (
        <FormUpdate
          parent={parent}

          object={object}

          onConfirm={() => refs.service.current[refs.queryObjectsName.current].value!.refetch.bind(refs.service.current[refs.queryObjectsName.current].value)(...refs.onConfirmProps.current)}

          singular={singular}

          plural={plural}

          {...refs.formUpdateProps.current}
        />
      )
    });
  }, [parent, FormUpdate, AppService.pages.add, secondary, singular, plural]);

  const init = React.useCallback(async () => {
    // items 
    const result = await refs.service.current[refs.queryObjectsName.current].value!.query({
      id: refs.parent.current?.id,

      query: {
        query: {
          ...refs.queryDefault.current,

          // ...(refs.pinned.current && { pinned: false })
        },

        sort: {
          // ...refs.sort.current
        }
      },
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      if (is('function', refs.onQueryAfter.current)) await refs.onQueryAfter.current!(result);

      if (!secondary) {
        let id: any = window.location.pathname.split('/').filter(Boolean).slice(-1)[0];

        id = isHex(`0x${id}`) ? id : undefined;

        // add 
        if (window.location.pathname.endsWith('/add')) onAddDefault();
        // open 
        else if (id) {
          const id = window.location.pathname.split('/').filter(Boolean).slice(-1)[0];

          if (isHex(`0x${id}`)) onOpenDefault(id, result.response.response);
        }
        else onClose();
      }
    }

    setLoaded(true);
  }, [service, secondary, queryDefaultHashed]);

  const reinit = React.useCallback(async () => {
    if (!secondary) {
      let id: any = window.location.pathname.split('/').filter(Boolean).slice(-1)[0];

      id = isHex(`0x${id}`) ? id : undefined;

      // add 
      if (window.location.pathname.endsWith('/add')) onAddDefault();
      // open 
      else if (id) {
        const id = window.location.pathname.split('/').filter(Boolean).slice(-1)[0];

        if (isHex(`0x${id}`)) onOpenDefault(id, refs.queryObjects.current?.response);
      }
      else onClose();
    }
  }, [service, secondary, queryDefaultHashed]);

  React.useEffect(() => {
    // init 
    init();
  }, [service, queryDefaultHashed]);

  React.useEffect(() => {
    // reinit 
    reinit();
  }, [location]);

  const onAddDefault = React.useCallback(() => {
    if (!refs.open.current || !FormAdd) return;

    AppService.pages[refs.appPriority.current as 'add'].emit({
      open: true,

      to: 'add',

      singular,

      plural,

      secondary,

      ...refs.addProps.current,

      children: (
        <FormAdd
          parent={parent}

          onConfirm={() => refs.service.current[refs.queryObjectsName.current].value!.refetch.bind(refs.service.current[refs.queryObjectsName.current].value)(...refs.onConfirmProps.current)}

          singular={singular}

          plural={plural}

          {...refs.formAddProps.current}
        />
      )
    });
  }, [parent, FormAdd, AppService.pages.add, secondary, singular, plural]);

  const onUnselectAll = React.useCallback(() => {
    service[selectObjectsName].value.unselectAll();
  }, [service, selectObjectsName]);

  const iconProps: any = {
    size: 'large'
  };

  const total = queryObjects?.pagination?.total || 0;

  const selected = selectObjects?.length;

  const viewName = (
    <Line
      gap={1}

      direction='row'

      wrap='wrap'

      justify='space-between'

      align='center'

      fullWidth
    >
      <Line
        gap={2}

        direction='row'

        align='center'
      >
        {startName}

        {is('string', name) ? (
          <Type
            version='h2'

            align='start'
          >
            {name}
          </Type>
        ) : name}

        {!!selected && (
          <Line
            gap={1}

            direction='row'

            align='center'
          >
            <Type
              version='h3'

              weight={300}
            >
              {selected} {selected === 1 ? singular : plural} selected
            </Type>

            <Tooltip
              name='Unselect all'
            >
              <IconButton
                onClick={onUnselectAll}
              >
                <IconMaterialSelectW100Rounded
                  {...iconProps}
                />
              </IconButton>
            </Tooltip>
          </Line>
        )}

        {endName}
      </Line>

      <Line
        gap={1}

        direction='row'

        align='center'
      >
        {!stripe && (
          <Type
            version='t1'
          >
            {total} {total === 1 ? singular : plural} found
          </Type>
        )}

        {add && (
          <Tooltip
            name={`Add ${singular || name?.toLowerCase() || 'new'}`}
          >
            <IconButton
              onClick={onAdd || (FormAdd && onAddDefault)}
            >
              <IconMaterialAddW100Rounded
                {...iconProps}
              />
            </IconButton>
          </Tooltip>
        )}
      </Line>
    </Line>
  );

  const viewMore = more && (
    <Line
      gap={1}

      direction='row'

      justify='space-between'

      align='center'

      fullWidth

      className={classes.actions}
    >
      <div />

      {more}
    </Line>
  );

  const viewSearch = search?.length ? (
    <Line
      gap={1}

      direction='row'

      justify='flex-start'

      align='center'

      fullWidth

      className={classes.actions}
    >
      <Search
        search={search}

        service={service}

        onQueryAfter={onQueryAfter}

        queryDefault={queryDefault}

        queryObjectsName={queryObjectsName}

        selectObjectsName={selectObjectsName}

        parent={parent}
      />
    </Line>
  ) : null;

  const views = <>
    {viewName}

    {viewMore}

    {viewSearch}

    {start}

    <Line
      gap={0}

      direction='column'

      justify='unset'

      align='unset'

      flex

      fullWidth
    >
      {is('function', children) ? (children as any)({ ...queryObjects }) : children}
    </Line>

    {end}
  </>;

  return (
    <Line
      ref={ref}

      gap={1}

      direction='column'

      justify='unset'

      align='unset'

      flex

      fullWidth

      {...other}

      className={classNames([
        className,
        classes.root,
        classes.wrapper
      ])}
    >
      {!loaded && <Loading />}

      {loaded && views}
    </Line>
  );
});

export default Element;
