import { Page } from '@amaui/api-utils';

import { BaseService } from './base';

class PageService extends BaseService<Page> {

  public constructor() {
    super('pages');
  }

}

export default new PageService();
