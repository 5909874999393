import { Contact } from '@amaui/api-utils';

import { BaseService } from './base';

class ContactService extends BaseService<Contact> {

  public get paginationLimit() { return 'all'; }

  public constructor() {
    super('contacts');
  }

}

export default new ContactService();
