import React from 'react';

import { Line, useForm, useSnackbars, useSubscription } from '@amaui/ui-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { classNames, style } from '@amaui/style-react';
import { debounce, hash, is } from '@amaui/utils';
import { IResponse } from '@amaui/sdk/other';

import { getErrorMessage, IQuerySubscription } from 'other';
import { Date, Select, TextField } from 'ui/element';

const useStyle = style(theme => ({
  root: {
    width: 'calc(100vw - 32px)'
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Search' });

export interface ISearchItem {
  name: string;

  type?: 'text' | 'select' | 'date';

  property: string;

  options?: any[];

  multiple?: boolean;
}

export interface ISearch extends IBaseElement {
  service: any;

  search?: ISearchItem[];

  queryDefault?: any;

  queryObjectsName?: string;

  parent?: any;

  onQueryAfter?: (result: IResponse) => any;
}

const Element: React.FC<ISearch> = React.forwardRef((props, ref) => {
  const {
    service,

    search,

    queryDefault,

    queryObjectsName = 'queryObjects',

    parent,

    onQueryAfter,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const queryObjects = useSubscription<IQuerySubscription>(service[queryObjectsName]);

  const [loaded, setLoaded] = React.useState(false);

  const form = useForm({
    values: {
      input: {
        name: 'Input',
        value: {
          ...queryDefault
        }
      }
    }
  });

  const refs = {
    form: React.useRef(form),
    parent: React.useRef(parent),
    queryDefault: React.useRef(queryDefault),
    queryObjectsName: React.useRef(queryObjectsName),
    onQueryAfter: React.useRef(onQueryAfter)
  };

  refs.form.current = form;

  refs.parent.current = parent;

  refs.queryDefault.current = queryDefault;

  refs.queryObjectsName.current = queryObjectsName;

  refs.onQueryAfter.current = onQueryAfter;

  React.useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 400);
  }, [queryObjects?.loaded]);

  const getItem = React.useCallback((item: ISearchItem, index: number) => {
    const otherProps: any = {
      key: index,

      name: item.name,

      value: refs.form.current.values.input.value[item.property],

      onChange: (valueNew: any) => refs.form.current.onChange('input', valueNew, item.property),

      size: 'small'
    };

    if (item.type === 'text') {
      return (
        <TextField
          clear

          {...otherProps}
        />
      );
    }

    if (item.type === 'select') {
      return (
        <Select
          options={item.options}

          multiple={item.multiple}

          {...otherProps}
        />
      );
    }

    if (item.type === 'date') {
      return (
        <Date
          {...otherProps}
        />
      );
    }

    return null;
  }, []);

  const onSearch = React.useCallback(debounce(async () => {
    const value = refs.form.current.value.input;

    const body: any = {
      ...refs.queryDefault.current,

      ...value
    };

    if (body.added_at) {
      const [addedAtFrom, addedAtTo] = value.added_at;

      if (addedAtFrom) body.added_at_from = addedAtFrom;

      if (addedAtTo) body.added_at_to = addedAtTo;

      delete body.added_at;
    }

    Object.keys(body).forEach(key => {
      if (body[key] === null) delete body[key];
    });

    const result = await service[refs.queryObjectsName.current].value!.query({
      id: refs.parent.current?.id,

      query: {
        ...service[refs.queryObjectsName.current].value!.previousQuery,

        query: {
          ...body,

          // ...(refs.pinned.current && { pinned: false })
        },

        sort: {
          // ...refs.sort.current
        },

        next: undefined,
        previous: undefined,
        skip: undefined,
        total: undefined
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    } else {
      if (is('function', refs.onQueryAfter.current)) await refs.onQueryAfter.current!(result);
    }
  }, 440), []);

  React.useEffect(() => {
    if (loaded) onSearch();
  }, [hash(form.value)]);

  if (!(search || service)) return null;

  return (
    <Line
      gap={1.5}

      direction='row'

      align='center'

      fullWidth

      className={classNames([
        classes.root,
        classes.actions
      ])}

      {...other}
    >
      {search?.map((item, index) => getItem(item, index))}
    </Line>
  );
});

export default Element;
