import React from 'react';

import { is, textToInnerHTML } from '@amaui/utils';
import { Line, Type } from '@amaui/ui-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { classNames, style } from '@amaui/style-react';

const useStyle = style(theme => ({
  root: {
    background: theme.palette.background.default.primary,
    borderRadius: 0,
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)'
  },

  item: {
    height: 100,
    padding: 24
  },

  itemIcon: {
    height: 100,
    aspectRatio: '1 / 1',
    backgroundColor: theme.palette.background.default.primary
  },

  itemImage: {
    height: 100,
    aspectRatio: '1 / 1',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundColor: theme.palette.background.primary.tertiary
  },

  itemValueAction: {
    cursor: 'pointer',
    userSelect: 'none'
  }
}), { name: 'amaui-app-ItemCard' });

export interface IItemCardItem {
  url?: string;

  icon?: any;

  entire?: boolean;

  name?: string;

  value?: any;

  align?: 'flex-start' | 'center' | 'flex-end';

  onClick?: any;

  props?: any;

  propsValue?: any;

  propsName?: any;
}

export interface IItemCard extends IBaseElement {
  items_start: IItemCardItem[];

  items_end?: IItemCardItem[];
}

const Element: React.FC<IItemCard> = React.forwardRef((props, ref: any) => {
  const {
    items_start,

    items_end,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const getItem = (item: IItemCardItem, index: number) => {
    const otherProps = {
      key: index,

      ...item.props,

      style: {
        ...(item.align && {
          alignSelf: item.align
        }),

        ...item.props?.style
      }
    };

    if (item.url !== undefined) {
      return (
        <Line
          {...otherProps}

          onClick={item.onClick}

          className={classNames([
            otherProps.className,

            classes.itemImage,
            item.onClick && classes.itemValueAction
          ])}

          style={{
            backgroundImage: `url(${item.url})`,

            ...otherProps.style
          }}
        />
      );
    }

    if (item.icon !== undefined) {
      return (
        <Line
          {...otherProps}

          onClick={item.onClick}

          align='center'

          justify='center'

          className={classNames([
            otherProps.className,

            classes.itemIcon,
            item.onClick && classes.itemValueAction
          ])}
        >
          <item.icon
            size='large'
          />
        </Line>
      );
    }

    return (
      <Line
        gap={0.5}

        align='unset'

        justify='center'

        {...otherProps}

        className={classNames([
          'amaui-app-ItemCard-item',
          otherProps.className,

          ...(!item.entire ? [classes.item] : [])
        ])}
      >
        {item.name && (
          <Type
            version='b2'

            whiteSpace='nowrap'

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(item.name)
            }}

            {...item.propsName}
          />
        )}

        {is('simple', item.value) ? (
          <Type
            version='t2'

            whiteSpace='nowrap'

            onClick={item.onClick}

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(item.value)
            }}

            {...item.propsValue}

            className={classNames([
              item.propsValue?.className,
              item.onClick && classes.itemValueAction
            ])}
          />
        ) : item.value}
      </Line>
    );
  };

  const methodFilter = React.useCallback((item: any) => [item.url, item.icon, item.value].some(item_ => item_ !== undefined), []);

  return (
    <Line
      ref={ref}

      gap={1}

      direction='row'

      wrap='wrap'

      align='flex-start'

      justify='space-between'

      fullWidth

      className={classNames([
        'amaui-app-ItemCard',

        className,
        classes.root
      ])}

      {...other}
    >
      <Line
        gap={0}

        direction='row'

        wrap='wrap'

        justify='flex-start'

        align='stretch'
      >
        {items_start?.filter(methodFilter).map((item, index) => getItem(item, index))}
      </Line>

      <Line
        gap={0}

        direction='row'

        wrap='wrap'

        justify='flex-end'

        align='stretch'

        flex

        style={{
          alignSelf: 'flex-end'
        }}
      >
        {items_end?.filter(methodFilter).map((item, index) => getItem(item, index))}
      </Line>
    </Line>
  );
});

export default Element;
